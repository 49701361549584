<template>
    <div class="alignRight">
      {{ organization }}
    </div>
  </template>
    
  <script>
  import axios from 'axios';
  
  export default {
    name: 'Organization',
    data() {
      return {
        organization: '',
      };
    },
    beforeMount() {
      this.feachData();
    },
  
    methods: {
      async feachData() {
        try {
          if (window.location.hash.includes('/mindspeller_network')) {
            return; // Do not proceed with the token update
          }
          const result = await axios.get('/api/cas/users/get_organization');
          if (result) {
            //display the string unless it is a demo organisation
            if(!result.data.startsWith("demo_organisation")){
              this.organization = result.data;
            }
          }
        } catch (error) {
          console.log(error);
        }
  
      },
    },
  
  };
  </script>
  
  <style>
  .alignRight {
    margin-right: 1.6vw;
    font-style: normal;
    font-weight: bold;
    font-size: 1.1vw;
    line-height: 1.5vw;
  }
  </style>
  