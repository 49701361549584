<template>
  <span>{{ displayedNumber }}</span>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      displayedNumber: this.number-100, // Start from 0 or any default value
      intervalId: null, // Track interval ID to manage counting
      timeoutId: null, // Track timeout ID for the delay
    };
  },
  watch: {
    number(newNumber) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId); // Clear any previous timeout
      }
      if (this.intervalId) {
        clearInterval(this.intervalId); // Clear any previous interval
      }
      this.startCountingWithDelay(newNumber); // Start counting after delay
    },
  },
  mounted() {
    this.startCountingWithDelay(this.number); // Start counting when the component is mounted
  },
  methods: {
    startCountingWithDelay(targetNumber) {
      const delay = 1000; // Delay in milliseconds
      const increment = Math.ceil(100 / 90); // Adjust speed by modifying this ratio
      const interval = 10000; // Update interval in ms
      const step = Math.max(1, increment); // Ensure at least +1 increment

      // Clear previous timeout and interval if any
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      // Add a delay before starting the count
      this.timeoutId = setTimeout(() => {
        // Initialize the displayed number to start counting
        this.displayedNumber = targetNumber - 100;

        this.intervalId = setInterval(() => {
          if (this.displayedNumber < targetNumber) {
            this.displayedNumber += step;
            if (this.displayedNumber >= targetNumber) {
              this.displayedNumber = targetNumber; // Cap to targetNumber
              clearInterval(this.intervalId);
            }
          } else {
            clearInterval(this.intervalId); // Stop if somehow the value exceeds
          }
        }, interval);
      }, delay); // Start counting after the specified delay
    },
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); // Clean up timeout when component is destroyed
    }
    if (this.intervalId) {
      clearInterval(this.intervalId); // Clean up interval when component is destroyed
    }
  },
};

</script>

<style scoped>
span {
  font-weight: bold;
  color: #333;
}
</style>
