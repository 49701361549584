<template>
    <div>
      <div v-if="showPopup" class="overlay" @click.self="closePopup">
        <div class="instructions-popup">
          <div class="popup-header">
            <h2>Notice</h2>
            <button class="close-button" @click="closePopup">✖</button>
          </div>
          <div v-if="isSearchBoxEnabled" class="step-container">
            <p>Add a Value of your choice by signing up for a free Mindspeller account. Click here to log in or sign up</p>
          </div>
          <div v-else class="step-container">
            <p>Get more personalized brand recommendations by signing up for a free Mindspeller account. Click here to log in or sign up</p>
          </div>
          <div class="popup-footer">
            <button class="primary-button" @click="openLoginPage">Login</button>
            <button class="primary-button" @click="openFreeTrialPage">Start Trial</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
    
    <script>
    import { mapGetters, mapActions } from "vuex";
    export default {
        props: {
            searchBox: {
            type: String, // Or the appropriate type for your data
            default: null // Default to null if not provided
            }
        },
      data() {
        return {
          showPopup: false, // Control the visibility of the popup
          isSearchBoxEnabled: false,
        };
      },
      computed: {
      // ...mapGetters(["showInstructionPopup"]), // Access popup state from Vuex
      },
      methods: {
        // ...mapActions(['initializeInstructionPopup', 'dismissInstructionPopup']),
        openPopup() {
          this.showPopup = true;
          // this.$store.dispatch('dismissInstructionPopup');
  
        },
        closePopup() {
          this.showPopup = false;
        },
        openLoginPage(){
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('jwt_refresh_token');
            localStorage.removeItem('is_anonymous');
            localStorage.removeItem('pageText');
            localStorage.removeItem('pageName');
            this.$router.push({name: 'Login'})
        },
        openFreeTrialPage(){
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('jwt_refresh_token');
            localStorage.removeItem('is_anonymous');
            localStorage.removeItem('pageText');
            localStorage.removeItem('pageName');
            this.$router.push({name: 'Free Trial'})
        },
      },
      mounted() {
        // Check if searchBox is passed and set internal state accordingly
        this.isSearchBoxEnabled = this.searchBox !== null;
    },
    };
    </script>
    
    <style scoped>
    /* Popup Container */
    .instructions-popup {
      width: 50%;
      max-height: 70%;
      background: #ffffff;
      border: 1px solid black;
      border-radius: 12px;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
      padding: 20px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow-y: auto;
      z-index: 1000;
      animation: fadeIn 0.3s ease-in-out;
      scrollbar-width: thin;
      scrollbar-color: #7878e9 transparent;
    }
    
    .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Ensures the overlay is above other elements */
    display: flex;
    align-items: center;
    justify-content: center;
  }
    /* Header */
    .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #000000;
      padding-bottom: 10px;
      margin-bottom: 5px;
    }
    
    .popup-header h2 {
      font-size: 1.8rem;
      font-weight: 600;
      color: #333;
      margin: 0;
    }
    
    .close-button {
      background: transparent;
      border: none;
      font-size: 1.5rem;
      color: #555;
      cursor: pointer;
      transition: color 0.3s ease;
    }
    
    .close-button:hover {
      color: #000;
    }
    
    /* Content */
    .popup-content {
      overflow-y: auto;
    }
    
    .step-container {
      margin-bottom: 30px;
      border: 1px solid #f0f0f0;
      border-radius: 8px;
      padding: 15px;
      background: #f9fbfd;
    }
    
    .step-container h3 {
      font-size: 1.5rem;
      font-weight: 500;
      color: #333;
      margin-bottom: 10px;
    }
    
    .step-image {
      width: 100%;
      max-height: 500px;
      object-fit: contain;
      margin: 10px auto;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
    
    .step-container p {
      font-size: 1rem;
      line-height: 1.6;
      color: #555;
    }
    
    /* Footer */
    .popup-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
    
    .primary-button {
      background: #4caf50;
      color: white;
      font-size: 1rem;
      font-weight: 600;
      padding: 10px 20px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background 0.3s ease;
    }
    
    .primary-button:hover {
      background: #45a049;
    }
    
    /* Animations */
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translate(-50%, -55%);
      }
      to {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    }
  
    @media screen and (min-width: 1600px) {
      .step-image {
          width: 100%;
      }
  
  }
  
  @media screen and (min-width: 1440px) and (max-width: 1599px) {
      .step-image {
          width: 100%;
      }
  
  }
  
  @media screen and (max-width: 1439px) and (min-width: 1024px) {
      .step-image {
          width: 80%;
      }
  }
    </style>
    