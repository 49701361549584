<template>
  <div class="custom-sidebar">
    <loading-app-wide v-if="loading" />
    <!-- Enhanced Sidebar Title -->
    <div v-if="this.lang_env === 'en'" class="leftbar-title">
      My Values
    </div>

    <word-picker v-if="showWordSelector && this.lang_env === 'en'" class="word-picker" @wordSelected="onWordSelected" :openAssetPicker="openAssetPicker" @addAsset="onAddAsset" :selected_assets_and_positionings="selected_assets_and_positionings" :show_positionings="show_positionings" @removeAsset="onRemoveAsset">
    </word-picker>

        <!-- <q-select dense outlined v-model="dummy" class=" form-select-sm" /> -->
    


    <asset-picker ref="asset-picker" :show_positionings="show_positionings" :allowed_types="allowed_types"
        @addAsset="onAddAsset">
    </asset-picker>

    
    <div v-if="sortedBrandResults.length">
    <div class="brand-table">
      <div class="brand-title-container">
        <div class="brand-title">
          <span>ASSOCIATIONS</span>
        </div>
      </div>
      
      <!-- Display only the first 'visibleResultsCount' results -->
      <div
        class="table-row"
        v-for="(brand, index) in sortedBrandResults.slice(0, visibleResultsCount)"
        :key="index"
      >
        <span class="table-column">
          <img
            v-if="brand.favicon"
            :src="brand.favicon"
            alt="favicon"
            class="favicon-img"
            @error="handleFaviconError(index)"
          />
          <span v-else>No Favicon</span>
        </span>
        <a
          :href="brand.website"
          target="_blank"
          rel="noopener noreferrer"
          class="brand-website-icon"
        >
          <font-awesome-icon :icon="['fas', 'external-link-alt']" />
          <span class="website-link">{{ brand.website }}</span>
        </a>
        <span class="table-column">{{ brand.name }}</span>
      </div>
      
      <!-- Load More row -->
      <div
        v-if="visibleResultsCount < sortedBrandResults.length"
        class="table-row load-more-row"
        @click="openLoginPage"
      >
        <span class="table-column load-more-text">Load More...</span>
      </div>
    </div>
  </div>

  <div v-else-if="isEmptyResults" class="leftbar-title">
    No recommendations available at the moment.
  </div>

    <!-- Table Display Below Images -->
    <div class="collapsible-info-box">

    <div class="info-header" @click="toggleInfoBox">
      <font-awesome-icon :icon="['fas', 'file-invoice']" class="header-icon" />
      <span>Concept Details</span>
      <font-awesome-icon :icon="collapsed_info ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']" class="toggle-icon" />
    </div>
    <div v-show="!collapsed_info" class="sidebar-title-container">
      <div class="sidebar-title">
        <span>{{ sidebarTitle }}</span>
      </div>
    </div>


    <div v-show="!collapsed_info" class="image-box">
      <div class="image-container">
        <img :src="imageUrl" :alt="`Image` " class="image-display"  @click="$refs.fullscreen_preview.open" />
      </div>
    </div>


    

    <div v-show="!collapsed_info" class="info-content">
      <div class="info-item">
        <span class="label">MAPPED ON</span>
        <span class="value">{{ tableData[0].value }}</span>
      </div>
      <div class="info-item">
        <span class="label">CONCEPT TYPE</span>
        <span class="value">{{ tableData[1].value }}</span>
      </div>
      <div class="info-item">
        <span class="label">WEBSITE</span>
        <span v-if="tableData[2].value === '-' || tableData[2].value === '.'">
          <font-awesome-icon :icon="['fas', 'external-link-alt']" class="disabled-icon" />
          <span class="website-link">-</span>
        </span>
        <a v-else :href="tableData[2].value" target="_blank" rel="noopener noreferrer" class="website-icon">
          <font-awesome-icon :icon="['fas', 'external-link-alt']" />
          <span class="website-link">{{ tableData[2].value }}</span>
        </a>
      </div>
    </div>
  </div>
    <!-- Instructions Section -->
    <!-- Collapsible Instructions Section -->
    <!-- <div class="instructions-box">
          <div class="instructions-header" @click="toggleInstructions">
            <font-awesome-icon :icon="['fas', 'info-circle']" class="header-icon" />
            <span>Instructions</span>
            <font-awesome-icon :icon="collapsed ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']" class="toggle-icon" />
          </div>
          <ul v-show="!collapsed" class="instructions-list">
            <li v-for="(instruction, index) in instructions" :key="index" class="instruction-item">
              <span>{{ instruction }}</span>
            </li>
          </ul>
        </div> -->
      <!-- Add exception text here -->
    <div v-if="this.lang_env === 'nl'" class="exception-text-container">
      <div class="exception-text">
        <font-awesome-icon
          :icon="['fas', 'exclamation-triangle']"
          class="warning-icon"
        />
        Opmerking: Het interactieve netwerk dat hier wordt weergegeven, is
        gebaseerd op het standaard internationale netwerk (Global English
        cultuur). Neem contact op met uw Account Manager als u een vergelijkbare
        visualisatie wilt voor de positionering van uw eigen concepten.
      </div>
    </div>
    </div>
  <full-screen-preview :wordData="wordData" ref="fullscreen_preview">
  </full-screen-preview>
  <B2CLoginPopup :searchBox="searchBox" ref="b2cLoginPopup"/>
</template>

<script>
import FullScreenPreview from "./FullScreenPreview.vue";
import WordPicker from '@/components/target_pickers/ias/WordPickerB2CDashboard.vue';
import AssetCheckbox from '@/components/target_pickers/assets/AssetCheckboxDashboard.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import B2CLoginPopup from './B2CLoginPopup.vue';
import AssetPicker from "@/components/asset_picker/AssetPicker.vue";
import { toast_success, alert_error } from '@/helpers/alert_helper.js';
import axios from 'axios';

export default {
  components: { FullScreenPreview, WordPicker, AssetCheckbox, LoadingAppWide, AssetPicker, B2CLoginPopup },
  emits: ['assetAdded', 'assetRemoved'],
  props: {
    wordData: {
      type: Object,
      required: true,
      default: () => ({ asset_id: null, word_id: null, word: '', type: '-', website:'', lang: '' })
    },
    background: {
      required: false,
      type: String,
      default: 'img-icon.png',
    },
    show_positionings: { default: false, type: Boolean },
    allowed_types: { default: ['images', 'words', 'multimedia'], type: Array },
    showWordSelector: {
            default: true,
            type: Boolean
    },
    iaHint: {
        default: '',
        type: String
    },
    isLabelRemoved: {
        default: true,
        type: Boolean
    },
  },
  data() {
    return {
      instructions: [
        "First enter a product category from the network and choose a value from the left side bar.",
        "Explore the top brand recommendations generated by Mindspeller.",
        "Isolating the selected node(s) will create a sub-network. Click the dashboard icon to reset.",
      ],
      tableData: [],
      selectedWord: null,
      imageUrl: '',
      collapsed: true,
      collapsed_info: true,
      selected_assets_and_positionings: new Set(),
      dummy: null,
      visibleResultsCount: 5,
      first_selected_word: null,
      loading: false,
      sortedBrandResults: [],
      isEmptyResults: false,
      restrictedDisplayNames: [
      'shutterstock_1284088828',
      '583571431',
      'shutterstock_1938550555',
      'shutterstock_635611256',
      '373369408',
      '1320625385',
    ],
    };
  },
  computed: {
    sidebarTitle() {
      return this.wordData && this.wordData.word ? this.wordData.word : "Node";
    },
    isRestrictedDisplayName() {
      return (
        this.first_selected_word &&
        this.restrictedDisplayNames.includes(this.first_selected_word.display_name)
      );
    },
    // shouldProcessAssociations() {
    //   // Trigger processAssociations when both wordData and first_selected_word are set
    //   return this.wordData && this.wordData.word_id && this.first_selected_word && this.first_selected_word.word.word_id;
    // }
  },
  watch: {
    // shouldProcessAssociations: {
    //   handler(newValue) {
    //     if (newValue) {
    //       // Start processing once the conditions are met
    //       const wordDataWordId = this.wordData.word_id;
    //       const selectedWordId = this.first_selected_word.word.word_id;
    //       this.processAssociations(wordDataWordId, selectedWordId);
    //     }
    //   },
    //   immediate: true
    // },
    wordData: {
      handler(newWordData) {
        // Check if image_url exists and update accordingly
        this.collapsed_info = !newWordData || Object.keys(newWordData).length === 0;
        this.updateImage(newWordData && newWordData.image_url);
        // Update the table data based on the new wordData values
        this.updateTable();

      },
      deep:true,
      immediate: true
    }
  },
  mounted() {
    // Initial fetch for tableData when component mounts
    this.updateTable();
  },
  methods: {
    openLoginPage(){
      this.$refs.b2cLoginPopup.openPopup();
    },
    toggleInfoBox() {
      this.collapsed_info = !this.collapsed_info;
    },
    handleFaviconError(index) {
      this.sortedBrandResults[index].favicon = require(`@/assets/${this.background}`);;
    },
    onWordSelected: async function (word) {
            let word_object = Object.assign({}, word);
            word_object.display_name = word.word;
            word_object.word = { 'word_id': word.word_id }
            let combined_object = { 'asset': word_object, 'positioning': '' }
            if (this.selected_assets_and_positionings.size > 0) {
                // Skip adding if a word is already selected
                if (
                  this.first_selected_word &&
                  this.restrictedDisplayNames.includes(this.first_selected_word.display_name)
                ) {
                  alert(`Select only one value. Already chosen one.`);
                  return;
                }
                alert(`Select only one value. Already chosen: ${ this.first_selected_word.display_name }`);
                return;
            }
            
            this.selected_assets_and_positionings.add(combined_object);
            this.$emit('assetAdded', combined_object);
            const msg = this.$t("message.added", { words: combined_object.asset.display_name });
            toast_success(msg);

            this.first_selected_word = word_object;
            const wordData_word_id = this.wordData && this.wordData.word_id ? this.wordData.word_id : '-';
            if (wordData_word_id !== '-'){
              await this.processAssociations(wordData_word_id,this.first_selected_word.word.word_id);
            }
    },
    reset: function () {
        this.selected_assets_and_positionings.clear();
    },
    onRemoveAsset: function (asset_and_positioning) {
            this.selected_assets_and_positionings.delete(asset_and_positioning);
            this.$emit('assetRemoved', asset_and_positioning);
            this.first_selected_word = [];
            this.isEmptyResults = false;
        },
    openAssetPicker: function () {
        this.$refs["asset-picker"].openAssetPicker();
    },
    onAddAsset: async function (payload) {
        // payload = {asset: xxx, positioning: xxx, key: xxx}
        if (this.selected_assets_and_positionings.size > 0) {
                // Skip adding if a word is already selected
                if (
                  this.first_selected_word &&
                  this.restrictedDisplayNames.includes(this.first_selected_word.display_name)
                ) {
                  alert(`Select only one value. Already chosen one.`);
                  return;
                }
                alert(`Select only one value. Already chosen: ${ this.first_selected_word.display_name }`);
                return;
          }
        this.selected_assets_and_positionings.add(payload);
        this.$emit('assetAdded', payload);
        const msg = `Added concept`;
        toast_success(msg);
        this.first_selected_word = payload.asset;
        // Check if wordData and first_selected_word are available, then call processAssociations
        const wordData_word_id = this.wordData && this.wordData.word_id ? this.wordData.word_id : '-';
        if (wordData_word_id !== '-') {
            const selectedWordId = payload.asset.word && payload.asset.word.word_id;
            if (selectedWordId) {
                await this.processAssociations(wordData_word_id, selectedWordId);
            }
        }
    },
    toggleInstructions() {
      this.collapsed = !this.collapsed;
    },
    updateTable() {
      // Set default values if `wordData.mapped` or `wordData.type` is undefined or null
      const mapped = this.wordData && this.wordData.mapped ? this.wordData.mapped : 'YYYY-MM-DD';
      const type = this.wordData && this.wordData.type ? this.wordData.type : '-';
      const website = this.wordData && this.wordData.website ? this.wordData.website : '-';
      const lang = this.wordData && this.wordData.lang ? this.wordData.lang : '-';
      this.lang_env = lang;
      this.tableData = [
        { title: 'Mapped on', value: mapped },
        { title: 'Concept Type', value: type },
        { title: 'Website', value: website },
      ];
      this.onRemoveAsset(this.asset_and_positioning);
      this.reset();
    },
    updateImage(image_url) {
      try {
        this.imageUrl = image_url ? image_url : require(`@/assets/${this.background}`);
      } catch {
        this.imageUrl = require(`@/assets/${this.background}`);
      }
    },
    loadMoreResults() {
      this.visibleResultsCount += 5;
    },
    async processAssociations(product, value){
      this.visibleResultsCount = 5;
            const payload = {
                "asset_1": product,
                "asset_2": value,
                "category": this.sidebarTitle,
            };
            this.sortedBrandResults = [];
            this.loading = true;
            try {
                const response = await axios.post('/projector/analyze', payload);
                this.loading = false;
                // Check if response and brand_results exist
                const data = response && response.data;
                const brandResults = data && data.brand_results;
                if (brandResults && Object.keys(brandResults).length > 0) {
                    this.sortedBrandResults = Object.entries(brandResults)
                        .map(([name, data]) => ({
                            name,
                            score: data.score,
                            website: data.website.startsWith('https://') || data.website.startsWith('http://') ? data.website : `https://${data.website}`,
                            favicon: data.favicon || require(`@/assets/${this.background}`),
                        }))
                        .filter(result => result.website !== "No website found")
                        .sort((a, b) => b.score - a.score); // Sort by score in descending order

                    this.isEmptyResults = false; // We have results, so no need for a fallback message
                } else {
                    // If brandResults is empty, set sortedBrandResults to an empty array and show "No recommendations"
                    this.sortedBrandResults = [];
                    this.isEmptyResults = true;
                }
              
              // Mark that the process has been completed
              this.hasProcessed = true;
              return response;
            } catch (e) {
                if (e.response.status === 403) {
                    alert_error(this.$t('message.no_access_to_items'));
                } else {
                    alert_error(this.$t('message.general_server_error'));
                }
            } finally {
            this.loading = false;
        }
        this.loading = false;
    }
  }
};
</script>

<style scoped>
.leftbar-title{
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  color: #333;
  text-align: center;
  font-weight: bold;
  padding: 8px 10px;
  /* margin-top: 2px;  */
  margin-left: auto; 
  margin-right: auto;
  margin-bottom: 10px;
  z-index: 4;
}
.custom-sidebar {
  position: fixed;
  top: 8%;
  left: 0;
  width: calc(100% - 52%);
  height: calc(100% - 100px);
  padding: 20px;
  z-index: 1;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}



.exception-text {
  font-size: 1rem;
  color: #7878e9;
  text-align: justify; /* Justify the text */
  font-weight: bold;
  padding: 10px;
  border-top: 1px solid #ccc;
  margin: 20px auto;
}

.warning-icon {
  color: red;
  font-size: 1em;
  margin-right: 10px;
  vertical-align: middle;
}

/* Custom scrollbar styling for Webkit browsers (Chrome, Safari) */
.custom-sidebar::-webkit-scrollbar {
  width: 6px;
}

.custom-sidebar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-sidebar::-webkit-scrollbar-thumb {
  background-color: #7878e9;
  border-radius: 4px;
}

.custom-sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #5a5dbb;
}

/* Sidebar Title */
.sidebar-title-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-title {
  background-color: transparent;
  border: 2px solid #7878e9;
  color: black;
  padding: 5px 0;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 95%;
  margin-top: 10%;
  overflow-wrap: break-word;
  white-space: normal;
}

/* Info Box */
.info-box {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  margin-top: 20px;
  width: 95%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.label, .value {
  color: #333;
}

.label {
  font-weight: bold;
}

.value {
  font-size: 0.85em;
  text-align: center;
}

/* Image Box */
.image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.image-container {
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.image-display {
  width: 100%;
  max-width: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 1;
}

.image-display:hover {
  transform: scale(1.2);
}

/* Instructions Box */
.instructions-box, .collapsible-info-box {
  background-color: #f9fbfd;
  border: 1px solid #7878e9;
  border-radius: 8px;
  padding: 5px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  transition: max-height 0.3s ease;
  margin-left: auto;
  margin-right: auto;
}

.instructions-header, .info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 15px; */
  font-size: 1em;
  font-weight: bold;
  border: 1px solid #eef3fa;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  margin-left: auto; margin-right: auto;
}

.instructions-header:hover, .info-header:hover {
  background-color: #a2c9ff;
}

.header-icon, .info-icon, .bullet-icon, .toggle-icon {
  color: #000000;
  transition: transform 0.3s;
}

.instructions-list, .info-content {
  padding: 15px;
}

.instructions-list {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.instruction-item {
  display: flex;
  align-items: center;
  background-color: #eef3fa;
  border-radius: 5px;
  padding: 10px;
  transition: background 0.3s;
}

.instruction-item:hover {
  background-color: #e2ebf6;
}

.instruction-item span {
  font-size: 1em;
  color: #333;
}

/* Website Icon Tooltip */
.website-icon {
  color: #7878e9;
  font-size: 1em;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.website-link {
  visibility: hidden;
  opacity: 0;
  background-color: #333;
  color: #fff;
  text-align: left;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.5em;
  position: absolute;
  z-index: 1;
  /* top: 90%; */
  left: 20%;
  white-space: normal;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
}

.website-icon:hover .website-link {
  visibility: visible;
  opacity: 1;
}

.brand-website-icon:hover .website-link {
  visibility: visible;
  opacity: 1;
}

/* Disabled Icon */
.disabled-icon {
  color: #ccc;
  cursor: default;
}

/* Brand table */
.brand-title-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.brand-title {
  background-color: transparent;
  color: black;
  padding: 5px 0;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 95%;
  margin-top: 10%;
  overflow-wrap: break-word;
  white-space: normal;
}

.brand-table {
  width: 100%; /* Ensures the table fits the container */
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: hidden;
  z-index: 1;
}

.table-header, .table-row {
  display: grid; /* Grid layout ensures consistent column alignment */
  grid-template-columns: 1fr 0.5fr 2fr; /* Adjust column widths */
  align-items: center;
  padding: 10px 20px;
  background-color: #f9fbfd;
  text-align: left;
}

.table-header {
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #ccc;
}

.favicon-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  object-fit: contain;
  vertical-align: middle;
}

.brand-website-icon {
  color: #7878e9;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.table-row:nth-child(even) {
  background-color: #b1b5ba;
}

.table-column {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  gap: 10px; /* Spacing between elements */
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-column:first-child {
  justify-content: flex-start; /* Align the first column to the left */
}

.table-column:last-child {
  justify-content: flex-end; /* Align the last column to the right */
}

.brand-name {
  text-align: right; /* Ensure text in the last column is aligned properly */
  margin-left: auto; /* Push the text to the right */
  font-weight: bold;
}

/* Load More Row Styles */
.load-more-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  background-color: #fafafa;
  overflow: hidden;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.load-more-row:hover {
  background-color: #e8e8e8;
  /* transform: scale(1.02); */
}

.load-more-text {
  font-weight: bold;
  color: #6c63ff;
}

/* Media Queries */
@media screen and (min-width: 1600px) {
  .custom-sidebar { top: 12.5%; height: calc(100% - 100px); width: 17%; padding: 25px; bottom: 0%; }
  .sidebar-title { font-size: 1.1em; padding: 10px 25px; }
  .image-display { max-width: 230px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 1.1em; width: 105%; margin-left: auto; margin-right: auto;}
  .instruction-item span, .table-column { font-size: 1em; }
  .exception-text-container {margin-top: 150%;}
  .brand-table { width: 105%; }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .custom-sidebar { top: 16%; height: calc(100% - 150px); width: 20%; padding: 20px; }
  .sidebar-title { font-size: 1em; padding: 5px 20px; }
  .image-display { max-width: 200px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 1em; width: 105%; margin-left: auto; margin-right: auto;}
  .instruction-item span, .table-column { font-size: 0.9em; }
  .brand-table { width: 105%; }
  .exception-text-container {margin-top: 75%;}
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  .custom-sidebar { top: 19%; height: calc(100% - 140px); width: 22%; padding: 15px; }
  .sidebar-title { font-size: 0.95em; }
  .image-display { max-width: 200px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 0.9em; width: 112%; margin-left: auto; margin-right: auto;}
  .instruction-item span, .table-column { font-size: 0.9em; }
  .brand-table { width: 112%; }
  .exception-text-container {margin-top: 20%;}
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .custom-sidebar { top: 24%; height: calc(100% - 140px); width: 28%; padding: 10px; }
  .sidebar-title { font-size: 0.9em; }
  .image-display { max-width: 180px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 0.85em;  width: 115%; margin-left: auto; margin-right: auto;}
  .instruction-item span, .table-column { font-size: 0.85em; }
  .brand-table { width: 115%; }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .custom-sidebar { top: 27%; height: calc(100% - 120px); width: 100%; padding: 10px; position: relative; }
  .sidebar-title { font-size: 0.85em; }
  .image-display { max-width: 150px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 0.8em;  width: 115%; margin-left: auto; margin-right: auto;}
  .instruction-item span, .table-column { font-size: 0.8em; }
  .brand-table { width: 100%; }
}

@media screen and (max-width: 479px) {
  .custom-sidebar { top: 30%; height: calc(100% - 100px); width: 100%; padding: 5px; position: relative; }
  .sidebar-title { font-size: 0.8em; padding: 5px 10px; }
  .image-display { max-width: 120px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 0.75em;  margin-left: auto; margin-right: auto;}
  .instruction-item { padding: 5px; }
  .instruction-item span, .table-column { font-size: 0.75em; }
  .brand-table { width: 100%; }
}
</style>


