<template>
  <main>
    <div class="container-fluid full-width-pg">
      <div class="free-trial-pg">
        <MapBrand />
        <div class="rgt-sec-bar">
          <div class="user-icon-wrapper" @click="openNetworkPage">
                    <font-awesome-icon :icon="['fas', 'project-diagram']" alt="Login" class="user-icon" />
                    <span class="login-text">B2C Mindspeller Network</span>
                </div>
          <div class="free-login-box">
            <div class="logo-imgbx">
              <img src="../../assets/logo-no-text.png" alt="" />
            </div>
            <div class="formbx">
              <form>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email:</label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Your Email Address"
                    @keydown.enter.prevent
                    v-model="emailData"
                    @input="validateEmail"
                  />
                </div>
                <q-dialog
                  style="max-width: none !important;"
                  v-model="basic"
                  transition-show="rotate"
                  transition-hide="rotate"
                >
                  <q-card
                    style="
                      background-color: #f6f6f6;
                      display: flex;
                      flex-direction: column;
                    "
                  >
                    <q-card-section style="max-height: 100%; overflow: auto;">
                      <General_terms></General_terms>
                    </q-card-section>

                    <q-card-actions style="margin-top: auto;">
                      <div
                        style="
                          display: flex;
                          justify-content: flex-start;
                          margin-bottom: 1vw;
                        "
                      >
                        <input
                          v-model="termsAccepted"
                          type="checkbox"
                          id="termsCheckbox"
                          style="margin-right: 1vw;"
                        />
                        <span style="font-weight: bold;"
                          >I have read and agree to the Mindspeller Terms and
                          Conditions.</span
                        >
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: flex-end;
                          margin-bottom: 1vw;
                        "
                      >
                        <button
                          v-close-popup
                          class="button_pay"
                          style="background-color: #92a0b3; margin-right: 1vw;"
                        >
                          Cancel
                        </button>
                        <button
                          v-close-popup
                          :disabled="!termsAccepted"
                          class="button_pay"
                          @click="proceedToPasswordSetup"
                        >
                          Agree
                        </button>
                      </div>
                    </q-card-actions>
                  </q-card>
                </q-dialog>

                <div class="btn-col">
                  <button
                    type="button"
                    v-bind:class="getClass()"
                    @click="openModal"
                    :disabled="this.buttonDisabled"
                  >
                    Start Trial
                  </button>
                </div>
              </form>
              <div class="google-login">
                <div class="logindiv">
                  Already have an account?
                  <router-link :to="{ name: 'Login' }">Log In </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <loading-app-wide v-if="loading" />
</template>

<script>
import General_terms from "./General_terms.vue";
import axios from "axios";
import MapBrand from "./Common/MapBrand.vue";
import AlternativeLangLink from "@/components/common/AlternativeLangLink";
import { alert_error } from "@/helpers/alert_helper.js";
import LoadingAppWide from "@/components/common/LoadingAppWide.vue";
import { isVNode, ref } from "vue";

export default {
  name: "FreeTrial",
  components: {
    MapBrand,
    AlternativeLangLink,
    LoadingAppWide,
    General_terms,
  },
  setup() {
    return {
      basic: ref(false),
    };
  },
  data: function () {
    return {
      termsAccepted: false,
      emailData: new String(),
      emailValid: new Boolean(),
      acceptedTos: false,
      loading: false,
    };
  },
  computed: {
    buttonDisabled: function () {
      if (
        // this.acceptedTos == true &&
        this.emailData.length > 0 &&
        this.emailValid
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    openNetworkPage(){
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('jwt_refresh_token');
      localStorage.removeItem('is_anonymous');
      localStorage.removeItem('pageText');
      localStorage.removeItem('pageName');
      this.$router.push({name: 'Mindspeller_Network'});
      return axios.post('/api/cas/token/refresh_for_network', {}).then(response => {
      localStorage.setItem('jwt_token', response.data['x-jwt-access-token']);
      localStorage.setItem('jwt_refresh_token', response.data['x-jwt-refresh-token']);
      localStorage.setItem('is_anonymous', 'true');

      });
    },
    openModal() {
      this.basic = true;
    },
    closeModal() {
      this.basic = false;
    },
    getClass() {
      return {
        btn3: !this.buttonDisabled,
        "disabled-btn": this.buttonDisabled,
      };
    },
    validateEmail: function () {
      const non_professional_providers = [
        // "gmail.com",
        // "yahoo.com",
        // "outlook.com",
        // "aol.com",
        // "icloud.com",
        // "hotmail.com",
        // "protonmail.com",
        // "zoho.com",
        // "mail.com",
        // "inbox.com",
        // "gmx.com",
        // "yandex.com",
        // "rocketmail.com",
        // "aim.com",
        // "lycos.com",
        // "rediffmail.com",
        // "mailinator.com",
        // "fastmail.com",
        // "tutanota.com",
        // "outlook.com",
        // "live.com",
        // "hotmail.co.uk",
        // "yahoo.co.uk",
        // "mail.ru",
        // "cox.net",
        // "earthlink.net",
        // "juno.com",
        // "btinternet.com",
        // "qq.com",
        // "163.com",
        // "126.com",
        // "yeah.net",
        // "sina.com",
        // "aliyun.com",
        // "foxmail.com",
      ];
      let regex_check = this.emailData
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (regex_check == null) {
        this.emailValid = false;
        return;
      }

      const isWorkEmail = non_professional_providers.some((domain) =>
        this.emailData.toLowerCase().endsWith(domain)
      );
      if (isWorkEmail) {
        this.emailValid = false;
        return;
      }

      this.emailValid = true;
      return;
    },

    proceedToPasswordSetup: async function () {
      this.validateEmail();
      this.loading = true;
      try {
        if (this.emailValid) {
          var access = await axios.post("/api/cas/users/exist/by_email", {
            email: this.emailData,
          });
          if (access.data == true) {
            alert_error("User already exsits");
          } else {
            let new_email = await axios.post(
              "/api/cas/started_registration/create",
              {
                email: this.emailData,
                last_step: 0,
              }
            );
            this.$router.push({
              name: "Password Setup",
              params: {
                userEmail: this.emailData,
              },
            });
          }
        } else {
          alert_error(this.$t("message.general_server_error"));
        }
      } catch (error) {
        if (error.response.status === 500) {
          alert_error(this.$t("message.general_server_error"));
        } else {
          alert_error(this.$t("login.not_a_user_yet"));
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style>
@import "../../css/onboarding_steps_style.scss";
@import "../../css/asset_style.scss";
@import "../../css/bootstrap.min.css";
@import "../../css/index.css";
@import "../../css/custom.css";
.button_pay {
  padding: 0.5vw 1vw;
  background: #0a00ff;
  border-radius: 2vw;
  font-style: normal;
  font-weight: 1000;
  font-size: 0.8vw;
  color: #ffffff !important;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 1s;
  box-shadow: none !important;
  border: none;
}

.button_pay a {
  color: #ffffff !important;
}

.button_pay:hover {
  background-color: #92a0b3;
}

.user-icon-wrapper {
  position: fixed;
  top: 5%; /* Adjust the distance from the top edge */
  right: 3%; /* Adjust distance from the right edge */
  background-color: black; /* Background color of the circle */
  border-radius: 50%; /* Make it circular */
  width: 40px; /* Circle size */
  height: 40px; /* Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-icon {
  color: white; /* Icon color */
  font-size: 20px; /* Adjust size as needed */
}

.login-text {
  position: absolute;
  bottom: -50px; /* Position the text below the user icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333; /* Dark background for the text */
  color: white; /* Text color */
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 0.8em;
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Ignore pointer events on the text */
  transition: opacity 0.3s ease; /* Smooth transition for appearing/disappearing */
}

.user-icon-wrapper:hover .login-text {
  opacity: 1; /* Show the text on hover */
}
</style>
