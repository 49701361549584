<template>
  <div v-if="showGamePopup" class="overlay" @click.self="closePopup">
    <div class="instructions-popup">
      <div class="popup-header" style="position: sticky; top: 0; background: #ffffff; z-index: 10; padding: 15px 0; border-bottom: 1px solid #ccc;">
        <div v-if="betPlaced && currentRound == 11" >
        <h2>You won {{ aggregateWinnings }} chips</h2>
        </div>
        <div v-else-if="betPlaced && currentRound == 10" >
        <h2>Enter hint (10/10)</h2>
        </div>
        <div v-else-if="betPlaced && currentRound == 9" >
        <h2>Enter hint (9/10)</h2>
        </div>
        <div v-else-if="betPlaced && currentRound == 8" >
        <h2>Enter hint (8/10)</h2>
        </div>
        <div v-else-if="betPlaced && currentRound == 7" >
        <h2>Enter hint (7/10)</h2>
        </div>
        <div v-else-if="betPlaced && currentRound == 6" >
        <h2>Enter hint (6/10)</h2>
        </div>
        <div v-else-if="betPlaced && currentRound == 5" >
        <h2>Enter hint (5/10)</h2>
        </div>
        <div v-else-if="betPlaced && currentRound == 4" >
        <h2>Enter hint (4/10)</h2>
        </div>
        <div v-else-if="betPlaced && currentRound == 3" >
        <h2>Enter hint (3/10)</h2>
        </div>
        <div v-else-if="betPlaced && currentRound == 2" >
        <h2>Enter hint (2/10)</h2>
        </div>
        <div v-else-if="betPlaced && currentRound == 1" style="display: flex; flex-direction: column; justify-content: space-between">
        <h2> STEP 3: Enter hint (1/10)</h2>
        <p>Keep the brand that popped in your mind during the previous steps.</p> <p> Provide one hint per round—starting with subtle associations and gradually moving to more obvious ones.</p>
        </div>
        <div v-else-if="roundReady" style="display: flex; flex-direction: column; justify-content: space-between">
        <h2>STEP 2: Place your bet between 100 to 500 Chips</h2>
        <p>Set the Stakes – Your wager determines the challenge and potential reward as you guide Mindspeller through your hints in the next rounds.</p>  
      </div>
        <div v-else-if="!gameStarted && selectedMode && !roundReady" style="display: flex; flex-direction: column; justify-content: space-between">
        <h2 style="font-size: 1.2rem;"> STEP 1: Think of a brand name</h2>
        <p>Get ready to begin the game! You have 10 rounds to give subtle hints that reflect spontaneous associations with the brand you’re thinking of.</p>
        <p> If you make it past round 5 without Mindspeller guessing your brand, you’ll start earning back your wager with a return.</p>
        </div>
        <div v-else style="display: flex; flex-direction: column; justify-content: space-between">
        <h2 style="font-size: 1.2rem;">Think you can outsmart Mindspeller?</h2>
        <h2 style="font-size: 1.2rem;">Place your bet now and see if we can guess the brand you're thinking of in <strong>3 simple steps.</strong></h2>
        <h2 style="font-size: 1.2rem;"> The more subtle your hints (associations), the bigger your reward!</h2>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <button class="close-button" style="border: 1px solid black; border-radius: 10px;margin-right: 10px; background-color: white; color: black;" @click="handleInstructionClick">
            <font-awesome-icon :icon="['fas', 'question']" />
        </button>
        <button class="coins" @click="handleCoinClick">
          <p>
            <font-awesome-icon :icon="['fas', 'coins']" size="1xl" class="font-awesome-icon" />
            {{ coinTotal }} Chips
          </p>
        </button>
        <button class="close-button" style="border: 1px solid black; border-radius: 10px;margin-left: 10px; background-color: red; color: black;" @click="closePopup">✖</button>
      </div>
    </div>
      <div class="popup-content">
        <div class="game-cta-container">

          <div class="cta-section">
            <button class="button" v-if="!play" @click="startGame">Play</button>
            <div class="round-counter" v-if="gameStarted && currentRound <= maxRounds">
            <h2>ROUND {{ currentRound }}</h2>
            </div>
            <button 
              v-if="betPlaced" 
              class="button" 
              style="
                display: flex;
                color: white; 
                border: none; 
                border-radius: 5px; 
                padding: 10px 20px; 
                cursor: pointer; 
                z-index: 1;"
              @click="resetGame"
            >
              Restart
            </button>

            <button 
              v-if="!gameStarted && !betPlaced && selectedMode" 
              class="button" 
              @click="backGame" 
              style="
                position: fixed; 
                bottom: 10px; 
                left: 10px; 
                background-color: black; 
                color: white; 
                border: none; 
                border-radius: 5px; 
                padding: 10px 20px; 
                cursor: pointer; 
                z-index: 1000;"
            >
              Back
            </button>          
          </div>
          <div class="game-lives-container" v-if="gameStarted">
              <game-lives :remaining-lives="lives"/>
          </div>
          <div class="game-container">

            <div v-if="loading" class="loader-overlay">
              Thinking...
            </div>
            <div v-if="showSameHintWarning" class="alert alert-error">
              <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                <div style="display: flex; flex-direction: column; justify-content: space-between;">
                  <p>You already gave this association!</p>
                  <p>Please provide a new one...</p>
                </div>
                <button class="close-button" @click="handleSameHintWarningClose">✖</button>
              </div>
            </div>
            <div v-if="showBetAmountWarning" class="alert alert-error">
              <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                <div style="display: flex; flex-direction: column; justify-content: space-between;">
                  <p>Please adjust your bet!</p>
                  <p>Bet amount should be between minimum 100 chips</p>
                </div>
                <button class="close-button" @click="handleBetAmountWarningClose">✖</button>
              </div>
            </div>
            <div v-if="showLostLifeWarning" class="alert alert-warning">
              <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                <div style="display: flex; flex-direction: column; justify-content: space-between;">
                  <p>Oops! That looks like a typo or non-English keyword. Remember: proper nouns are CASE-SENSITIVE, and misspelled hints cost a life. You have 3 lives—use them wisely!</p>
                </div>
                <button class="close-button" @click="handleLostLifeWarningClose">✖</button>
              </div>
            </div>
            <div  v-if="!gameStarted && selectedMode && !roundReady">
              <button class="button" style="width: 100%; font-size: large; padding-left: 40px; padding-right: 40px;" @click="step3Ready">Next</button>
            </div>
            <div class="place-bet-container" v-if="roundReady && !gameStarted && play">
              <div class="bet-input">
                <label for="betAmount">Enter bet amount: </label>
                <input id="betAmount" v-model="betAmount" type="number" class="bet-field" />
              </div>
              <div class="difficulty-select">
                <label for="difficulty">Select Difficulty:</label>
                <select id="difficulty" v-model="difficulty" class="difficulty-field">
                  <option value="Starter">Starter</option>
                  <option value="Medium">Medium</option>
                  <option value="Pro">Pro</option>
                </select>
              </div>
              <button class="button" @click="placeBet">Place Bet</button>
            </div>
           
            <div class="play-game-container" v-if="gameStarted">
              <div class="round-tracker"  v-if="currentRound <= maxRounds">
                <div style="margin: 20px auto; width:100%; text-align: center;">
                  <label for="hintInput">Enter your brand association (hint): </label>
                  <input id="hintInput" v-model="lastHint" type="text" class="hint-field" />
                  <button class="button" style="padding: 3.5px; border-radius: 4px;margin-left: 2px;" @click="submitHint">Submit</button>
                </div>
                <!-- <details class="round-tracker-instructions" style="margin-top: 10px; text-align: center;">
                  <summary class="round-tracker-instructions-button">Instructions</summary>
                  <p></p>
                  <p>During each round, input your most effective association for the brand. Each quality association increases your potential to earn more chips.</p>
                  <p>Continue playing until all 10 rounds are completed, even if a win is achieved before then.</p>
                  <p>Upon completion of 10 rounds, a quality assessment will be conducted to verify the brand against the provided associations. If the associations are found to be ambiguous or unclear, the earned bet amount will not be credited.</p>
                </details> -->
                <!-- <div class="instructions-box">
                  <div class="instructions-header" @click="toggleInstructions">
                    <font-awesome-icon :icon="['fas', 'info-circle']" class="header-icon" />
                    <span>Instructions</span>
                    <font-awesome-icon :icon="collapsed ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']" class="toggle-icon" />
                  </div>
                  <ul v-show="!collapsed" class="instructions-list">
                    <li v-for="(instruction, index) in instructions" :key="index" class="instruction-item">
                      <span>{{ instruction }}</span>
                    </li>
                  </ul>
                </div> -->
                <p v-if="hintMessage">{{ hintMessage }}</p>
              </div>

              <div v-if="isModalVisible" class="dialog">
                <div class="dialog-content">
                  <h3>Is this the brand you're thinking of?</h3>
                  <p><strong>{{ currentGuess }}</strong></p>
                  <div class="dialog-actions">
                    <button class="button" @click="closeGuessConfirmationDialog">No</button>
                    <button class="button" @click="confirmGuessConfirmationDialog">Yes</button>
                  </div>
                </div>
              </div>
              <div v-if="currentRound == maxRounds + 1 && tenthround" class="dialog">
                <div class="dialog-content">
                  <h3>You did not win anything</h3>
                  <div class="dialog-actions">
                    <button class="button" @click="resetGame">Restart</button>
                    <button class="button" @click="closePopup">Close</button>
                  </div>
                </div>
              </div>
              <div v-if="betAmount == aggregateWinnings && newBrand" class="dialog">
                <div class="dialog-content" >
                  <h4>We guessed it!</h4>
                  <h4>Your bet is break even</h4>
                  <h4> But let’s keep playing; you’re not the only one with a predictive top of mind. 😉</h4>
                  <p v-if="difficulty === 'Starter'">Think of a new brand associated with the keyword</p>
                  <p v-else>Think of a new brand associated with the keyword(s) combined:</p>
                  <p><strong>{{ formattedHints }}</strong></p>
                  <button class="close-button" @click="closeNewBrandDialog">✖ Close</button>
                </div>
              </div>
              <div v-else-if="currentRound <= maxRounds && currentRound >= 6 && newBrand" class="dialog">
                <div class="dialog-content" >
                  <h4>We guessed it!</h4>
                  <h4> You still have a chance to win it all back</h4>
                  <h4> But let’s keep playing; you’re not the only one with a predictive top of mind. 😉</h4>
                  <p v-if="difficulty === 'Starter'">Think of a new brand associated with the keyword</p>
                  <p v-else>Think of a new brand associated with the keyword(s) combined:</p>
                  <p><strong>{{ formattedHints }}</strong></p>
                  <button class="close-button" @click="closeNewBrandDialog">✖ Close</button>
                </div>
              </div>
              <div v-else-if="currentRound <= maxRounds && newBrand" class="dialog">
                <div class="dialog-content" >
                  <h3>We guessed it!</h3>
                  <h3> But let’s keep playing; you’re not the only one with a predictive top of mind. 😉</h3>
                  <p v-if="difficulty === 'Starter'">Think of a new brand associated with the keyword</p>
                  <p v-else>Think of a new brand associated with the keyword(s) combined:</p>
                  <p><strong>{{ formattedHints }}</strong></p>
                  <button class="close-button" @click="closeNewBrandDialog">✖ Close</button>
                </div>
              </div>
              <div v-if="isFinalModalVisible" class="dialog"  style="width: 50%;">
                <div class="dialog-content">
                  <h3 v-if="isBrandCheck">Game Ended</h3>
                  <h3 v-else-if="!isBrandCheck">Game Over</h3>
                  <p v-if="isBrandCheck && aggregateWinnings > 0">Congratulations, you won {{ aggregateWinnings }} Chips!</p>
                  <p v-else-if="isBrandCheck && aggregateWinnings <= 0">You won {{ aggregateWinnings }} Chips!</p>
                  <p v-if="isBrandCheck && aggregateWinnings < 2*betAmount" style="font-size: small;">
                    <li v-for="(entry, index) in aggregateRounds" :key="index">
                      <span v-if="entry.round < 6">
                        Mindspeller won in round {{ entry.round }}. Restarted with bet {{ betAmount - entry.winning }} Chips. Lost {{ entry.winning }} chips.
                      </span>
                      <span v-else>
                        Mindspeller won in round {{ entry.round }}. Restarted with winnings {{ 2*betAmount - entry.winning }} Chips.
                      </span>
                    </li>
                  </p>
                  <p v-else-if="!isBrandCheck">Your bet amount {{ betAmount }} Chips is given back.</p>
                  <div v-if="isSystemBeaten && isBrandCheck">
                    <p v-if="aggregateWinnings == 2*betAmount">You outsmarted the system—impressive work! What brand were you thinking of?</p>
                    <p v-else>What brand were you thinking of?</p>
                    <input v-model="userBrand" type="text" class="brand-field" />
                    <button class="button" style="padding: 5px; margin-left: 2px;" @click="submitUserBrand">Submit Brand</button>
                    <p v-if="isBrandSubmitted">Thanks for sharing your top of mind brand! Feeling lucky? Play another round to improve your brand recall skills!</p>
                  </div>
                  <div v-if="isBrandCheck">
                  </div>
                  <div v-else-if="brandInHistory">
                    <p>Already guessed brand was submitted. Try again!</p>
                  </div>
                  <div v-else>
                    <p>Hints entered since round {{lastEntryRound}} and the brand submitted did not match. Try again!</p>
                  </div>
                  <div v-if="isBrandSubmitted" class="dialog-actions">
                    <button class="button" @click="closePopup" :disabled="!isBrandSubmitted && isSystemBeaten">Exit</button>
                    <button class="button" @click="resetGame" :disabled="!isBrandSubmitted && isSystemBeaten">Play Again</button>
                    
                  </div>
                  <button v-if="aggregateWinnings <= 0 && !brandInHistory && isBrandCheck" class="button" @click="resetGame" >Play Again</button>
                </div>
              </div>
              <div class="hint-table">
                <table>
                  <thead>
                    <tr>
                      <th>Round</th>
                      <th>Association</th>
                      <th>Guessed Brand</th>
                      <th>Winnings</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(entry, index) in history" :key="index">
                      <td>{{ entry.round }}</td>
                      <td>{{ entry.hint }}</td>
                      <td>{{ entry.brand }}</td>
                      <td>{{ entry.penalty }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showCoinPopup" class="overlay" @click.self="closeCoinPopup">
    <div class="coin-popup" style="width: 50%;">
      <div class="popup-header">
        <h2>Notice</h2>
        <button class="close-button" @click="closeCoinPopup">✖</button>
      </div>
      <div class="popup-content">
        <p>Your chips are for practice only. Sign up for a Mindspeller account to start betting with real Mindcoins!</p>
          <button class="button" @click="handleLoginClick">Login</button>
          <button class="button" style="margin-left: 5px;" @click="closeCoinPopup">Close</button>
      </div>
    </div>
  </div>
  <div v-if="showInstructionPopup" class="overlay" @click.self="closeInstructionPopup">
    <div class="coin-popup" style="width: 50%; height: 100%; overflow-y: auto">
      <div class="popup-header">
        <h2>Instructions</h2>
        <button class="close-button" @click="closeInstructionPopup">✖</button>
      </div>
      <div class="popup-content">
        <ul class="instructions-list">
          <li v-for="(instruction, index) in instructions" :key="index" class="instruction-item">
            <span>{{ instruction }}</span>
          </li>
          <div class="popup-header">
            <h2>Rules</h2>
          </div>
          <li v-for="(rule, index) in rules" :key="index">
            <span>{{ rule }}</span>
          </li>
        </ul>
          <button class="button" style="margin-left: 5px;" @click="closeInstructionPopup">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import GameLives from './GameLives.vue';
export default {
  components: { 
    GameLives, 
  },
  data() {
    return {
      gameStarted: false,
      betPlaced: false,
      selectedMode: '',
      currentRound: 1,
      lastEntryRound: 1,
      maxRounds: 10,
      gameOver: false,
      betAmount: 0,
      difficulty: 'Starter',
      hints: [],
      aggregateRounds: [],
      history: [],
      newBrand: false,
      hintMessage: '',
      lastHint: '',
      currentPenalty: 0,
      isModalVisible: false,
      currentGuess: '',
      aggregateWinnings: 0,
      isFinalModalVisible: false,
      isSystemBeaten: false,
      userBrand: '',
      savedData: [],
      duplicateGuess: [],
      showSameHintWarning: false,
      showBetAmountWarning: false,
      showLostLifeWarning: false,
      lives:3,
      loading: false,
      isBrandSubmitted: false,
      isBrandCheck: true,
      showGamePopup: false,
      showCoinPopup: false,
      showInstructionPopup: false,
      tenthround: false,
      collapsed: true,
      play: false,
      roundReady: false,
      brandInHistory: false,
      gptHints: [],
      instructions: [
        "STEP 1: Get ready to begin the game! You have 10 rounds to give subtle hints that reflect spontaneous associations with the brand you’re thinking of. If you make it past round 5 without Mindspeller guessing your brand, you’ll start earning back your wager with a return.",
        "STEP 2: Set the Stakes – Your wager determines the challenge and potential reward as you guide Mindspeller through your hints in the next rounds.",
        "STEP 3: Keep the brand that popped in your mind during the previous steps. Provide one hint per round—starting with subtle associations and gradually moving to more obvious ones."
        // "During each round, input your most effective association for the brand. Each quality association increases your potential to earn more chips.",
        // "Continue playing until all 10 rounds are completed, even if a win is achieved before then.",
        // "Upon completion of 10 rounds, a quality assessment will be conducted to verify the brand against the provided associations. If the associations are found to be ambiguous or unclear, the earned bet amount will not be credited.",
      ],
      rules: [
        "During each round, input your most subtle yet spontaneous brand association. Challenge Mindspeller by avoiding hints that make it too easy to guess your brand. Every brand association that leads Mindspeller to an incorrect guess increases your potential to earn more chips.",
        "Keep playing until all 10 rounds are completed, even if Mindspeller wins before the final round.",
        "- Before Round 5: If Mindspeller wins, the remaining bet will be carried forward to the upcoming rounds.",
        "- After Round 5: If Mindspeller wins, the winnings will be used as the bet for the remaining rounds.",
        "At the end of 10 rounds, a quality review will be conducted to ensure the associations provided accurately reflect the brand. If the associations are deemed ambiguous or unclear, the earned bet amount will not be credited.",
      ],
    };
  },
  watch: {
    currentRound(newVal) {
      if (newVal > this.maxRound) {
        this.checkGameOver();
      }
    }
  },
  computed: {
    relevantHints() {
      const hintCount = this.difficulty === 'Pro' ? 3 : this.difficulty === 'Medium' ? 2 : 1;
      return this.hints.slice(-hintCount).reverse();
    },
    formattedHints() {
      return this.relevantHints.map(hint => hint.charAt(0).toUpperCase() + hint.slice(1)).join(', ');
    },
    ...mapState(['coinTotal']),
  },
  methods: {
    ...mapActions(['subtractCoins','updateCoinTotal', 'addCoins']),
    ...mapGetters(['getCoinTotal']),
    openPopup() {
      this.showGamePopup = true;
    },
    closePopup() {
      this.showGamePopup = false;
      if(this.showCoinPopup){
      this.showCoinPopup = false;
      }
      this.selectedMode = '';
      this.resetGame();
    },
    openCoinPopup() {
      this.showCoinPopup = true;
    },
    closeCoinPopup() {
      this.showCoinPopup = false;
    },
    openInstructionPopup() {
      this.showInstructionPopup = true;
    },
    closeInstructionPopup() {
      this.showInstructionPopup = false;
    },
    handleCoinClick() {
      this.openCoinPopup();
    },
    handleInstructionClick(){
      this.openInstructionPopup();
    },
    handleLoginClick() {
      window.open(this.$router.resolve('/login').href, '_blank');
    },
    toggleInstructions() {
      this.collapsed = !this.collapsed;
    },
    startGame() {
      this.selectedMode = 'single';
      this.play = true;
    },
    backGame() {
      this.selectedMode = false;
      this.roundReady = false;
      this.play = false;
    },
    step3Ready(){
      this.roundReady = true;
    },
    handleSameHintWarningClose(){
      this.showSameHintWarning = false
    },
    handleBetAmountWarningClose(){
      this.showBetAmountWarning = false
    },
    handleLostLifeWarningClose(){
      this.showLostLifeWarning = false
    },
    resetGame() {
      this.gameStarted = false;
      this.betPlaced = false;
      this.currentRound = 1;
      this.lastEntryRound = 1;
      this.maxRounds = 10;
      this.gameOver = false;
      if (this.hints.length === 0) {
        this.addCoins(this.betAmount);
      }
      this.betAmount = 0;
      this.difficulty = 'Starter';
      this.hints = [];
      this.gptHints = [];
      this.history = [];
      this.newBrand = null;
      this.hintMessage = '';
      this.lastHint = '';
      this.isFinalModalVisible = false;
      this.isModalVisible = false;
      this.isSystemBeaten = false;
      this.userBrand = '';
      this.duplicateGuess = [];
      this.showSameHintWarning = false;
      this.showLostLifeWarning = false;
      this.showBetAmountWarning = false;
      this.selectedMode = 'single';
      this.isBrandSubmitted = false;
      this.brandInHistory = false;
      this.tenthround = false;
      this.play = false;
      this.roundReady = false;
      this.brandInHistory = false;
      this.aggregateRounds = [];
      this.showInstructionPopup = false;
      this.startGame();

    },
    async submitUserBrand(){
      this.loading = true;
      const brandcheck = await this.submittedbrandcheck(this.userBrand,this.gptHints);
      const isBrandInHistory = this.history.some(entry => entry.brand.toLowerCase() === this.userBrand.toLowerCase());
      this.loading = false;
      if(isBrandInHistory){
        this.brandInHistory = true;
      }
      if (brandcheck > 5 && !isBrandInHistory) {
      this.savedData.push({"brand": this.userBrand,"associations":this.hints})
      this.isBrandSubmitted= true;
      this.isBrandCheck = true;
      }
      else{
        this.isBrandSubmitted= true;
        this.isBrandCheck = false;
        this.subtractCoins(this.aggregateWinnings-this.betAmount);
        this.aggregateWinnings = this.aggregateWinnings-this.betAmount;
      }
    },

    async submittedbrandcheck(brand, hints){
      const prompt = `
        Return a score based on the average semantic association score (on a scale from 0 to 10, 10 being the highest) for all hints entered based on its relevancy to the brand. Strictly only provide the score.

        Hints:
        ${hints.map((hint, index) => `${index + 1}. ${hint}`).join('\n')}

        Entered brand: ${brand}
      `;

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: [{ role: "user", content: prompt }],
          max_tokens: 10
        })
  });

  const data = await response.json();
  return data.choices[0].message.content.trim();
    },
    placeBet() {

      if (this.betAmount >= 100 && this.betAmount <= this.getCoinTotal()) {
        this.betPlaced = true;
        this.subtractCoins(this.betAmount);
        this.gameStarted = true;
      } else {
          this.showBetAmountWarning=true;
      }

    },
    closeGuessConfirmationDialog() {
      if (this.currentRound == 11){
        this.isSystemBeaten = true;
        this.checkGameOver();
      }
      this.duplicateGuess.push(this.currentGuess);
      this.isModalVisible = false;
    },
    confirmGuessConfirmationDialog() {
      this.isModalVisible = false;
      this.savedData.push({"brand": this.currentGuess,"associations":this.gptHints});
      // this.duplicateGuess=[]; //reason for commenting: We shouldn’t guess the same brand if we just instructed user to think of a “new” brand.
      this.duplicateGuess.push(this.currentGuess);
      this.newBrand = true;
      if(this.currentRound === this.maxRounds + 1 && this.betAmount > this.aggregateWinnings){
        this.tenthround = true;
      }
    },
    closeNewBrandDialog(){
      this.aggregateWinnings = Math.round(Number(this.currentPenalty));
      this.lastEntryRound = this.currentRound;
      this.aggregateRounds.push({"round": this.currentRound,"winning":this.aggregateWinnings});
      this.gptHints = this.relevantHints; // for ambiguity check. if mindspeller won inbetween we refresh the hints till round 10 (incase user wins).
      // this.hints=this.relevantHints; //reason for commenting: We don’t want to show the user the hints they used to guess throughout the game.
      this.newBrand = false;
    },
    closePlayAgainDialog(){
      this.isFinalModalVisible = false;
      this.$router.push({ name: 'Mindspeller_Network' });
    },
    async submitHint() {
      this.loading=true;
      const hint = this.lastHint;
      if (this.hints.includes(hint)){
        this.loading=false;
        this.showSameHintWarning=true;
        this.lastHint='';
        return true;
      }
      const gibCheck = await this.checkBrandHint(hint);
      if (gibCheck==="No"){
        this.lives = this.lives-1;
        this.showLostLifeWarning=true;
        if (this.lives==-1){
          this.checkGameOver();
        }
        this.loading=false;
        return true;
      }
      this.gptHints.push(hint);
      this.hints.push(hint);
      const guess = await this.getBrandGuess(this.gptHints);
      this.currentGuess = guess;
      this.history.push({
        round: this.currentRound,
        hint: hint,
        brand: guess,
        penalty: this.calculatePenalty(this.currentRound)
      });
      this.lastHint = '';
      this.isModalVisible = true;
      this.checkGameOver();
    },
    async getBrandGuess(inputHints) {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: [{ role: "user", content: `Guess the brand based on these hints: ${inputHints}. Return only one guess that is not one of the: ${this.duplicateGuess}` }],
          max_tokens: 10
        })
      });
      const data = await response.json();
      return data.choices[0].message.content.trim();
    },
    async checkBrandHint(hint) {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: [{ role: "user", content: `Is this a valid keyword in english: ${hint}. Return yes or no answer.` }],
          max_tokens: 10
        })
      });
      const data = await response.json();
      return data.choices[0].message.content;
    },
    calculatePenalty(roundNumber) {
      if (roundNumber <= 5){
        this.currentPenalty = Number(this.betAmount)-(this.maxRounds/2 - roundNumber)*0.2*Number(this.betAmount)
      }
      else{
        this.currentPenalty = Number(this.betAmount)+(roundNumber - this.maxRounds/2)*0.2*Number(this.betAmount)
      }
      return this.currentPenalty.toFixed(0)
    },
    
    async generateHints() {
      const geolocation = await this.getGeolocation();
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: [{ role: "user", content: `Generate one keyword association for a brand relevant to the player located in ${geolocation.city}, ${geolocation.country}:` }],
          max_tokens: 50
        })
      });
      const data = await response.json();
      return data.choices[0].message.content.trim().split('\n').filter(hint => hint);
    },
    async getGeolocation() {
      const response = await fetch('https://ipapi.co/json/');
      return response.json();
    },
    checkGameOver() {
      if (this.currentRound > this.maxRounds) {
        this.gameOver = true;
        this.aggregateWinnings = Math.round(Number(this.currentPenalty)-Number(this.aggregateWinnings));
        this.addCoins(this.aggregateWinnings);
        this.isFinalModalVisible = true;
        this.loading=false;
        return;
      }
      if (this.lives==-1){
        this.gameOver = true;
        this.aggregateWinnings=0;
        this.addCoins(this.aggregateWinnings);
        this.isFinalModalVisible = true;
        this.loading=false;
        return;
      }
      this.currentRound++;
      this.loading=false;
    }
  },
};
</script>

<style>
.game-cta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.cta-section {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
}
.instructions {
  display: flex;
  width: 70%;
  flex-direction: column;
  margin-top: 20px;
  text-align: justify;
  text-align-last: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #555;
  line-height: 1.6;
  /* background-color: #f8f9fa; */
  padding: 15px;
  /* border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.round-tracker-instructions {
  margin-top: 10px auto;
  text-align: center;
  /* width: 80%; */
  clear: both;
  justify-content: center;

}

.round-tracker-instructions p{
  text-align: left;
  margin: 10px auto;
  font-size: 15px;
}
.round-tracker-instructions-button {
    background-color: #000000;
    color: white;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }

.round-tracker-instructions-button:hover {
    background-color: #7c7c7c;
  }
.button {
  background-color: green;
  color: white;
  font-weight: bold;
  border-radius: 5%;
  border: 0;
  cursor: pointer;
  margin: 10px;
  padding: 10px;
}
.loader-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid black;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  z-index: 9999;
}
.instructions-popup {
  width: 70%;
  max-height: 90%;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
}

.coin-popup {
  width: 30%;
  max-height: 90%;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  animation: fadeIn 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #000000;
  background-color: white;
  padding-bottom: 10px;
  margin-bottom: 5px;
}
.popup-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}
.popup-header p {
  font-size: 0.9rem;
  font-weight: 400;
  width: 80%;
  color: #333;
  margin: 0;
}
.close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}
.close-button:hover {
  color: #000;
}

.coins {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.coins p {
  margin: 0;
  font-size: 0.8rem;
  width: 100%;
  font-weight: bold;
  color: #333;
}

.font-awesome-icon{
  margin-right: 0;
}

.alert {
  border: 1px solid;
  padding: 10px;
  border-radius: 8px;
  margin: 15px 0;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.alert-error {
  border-color: red;
  background-color: #ffe6e6;
  color: red;
}
.alert-warning {
  border-color: orange;
  background-color: #fff4e5;
  color: orange;
}
.dialog {
  position:fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 20px;
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: 500px;
  max-width: 80%;
}
.dialog-content {
  text-align: center;
}
.dialog-content h3 {
  font-size: 1.5rem;
  font-weight: bold;
  /* margin-bottom: 5px; */
}
.dialog-content h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}
.dialog-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}
.hint-table {
  width: 100%;
  overflow-x: auto;
  margin: 0px 0;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
.hint-table h2{
  font-size: 3em;
}
.hint-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.game-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-top: 0%;
  margin-left: 1%;
  margin-right: 1%;
  height: 70%;
  width:100%
}
.play-game-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  margin: 0 0;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.round-tracker {
  display: flex;
  flex-direction: column;
  width: 35%;
  align-items: center;
  padding: 20px;
  /* background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.round-counter {
  text-align: center;
  font-size: 1.1em;
  width: 100%;
  font-weight: bold;
  margin-left: 6%;
  color: #333;
  flex: 1;
}

.round-tracker h2{
  font-size: 3em;
}

.round-counter h2{
  font-size: 3em;
}

.fixed-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.game-lives-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
}

.place-bet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 20px auto;
  gap: 20px;
}
.place-bet-container h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.bet-input, .difficulty-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}
.bet-input label, .difficulty-select label {
  font-weight: bold;
  margin-bottom: 5px;
}
.bet-field, .difficulty-field {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
}
.button {
  background-color: #28a745;
  color: white;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin: 10px auto;
  padding: 15px 25px;
  transition: background-color 0.3s;
}
.button:hover {
  background-color: #218838;
}
.cta-section {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}

/* Instructions Box */
.instructions-box{
  background-color: #f9fbfd;
  border: 1px solid #7878e9;
  border-radius: 8px;
  padding: 5px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 70%;
  transition: max-height 0.3s ease;
  margin-left: auto;
  margin-right: auto;
}

.instructions-header, .info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 15px; */
  font-size: 1em;
  font-weight: bold;
  border: 1px solid #eef3fa;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  margin-left: auto; margin-right: auto;
}

.instructions-header:hover, .info-header:hover {
  background-color: #a2c9ff;
}


.instructions-list, .info-content {
  padding: 15px;
}

.instructions-list {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.instruction-item {
  display: flex;
  align-items: center;
  background-color: #eef3fa;
  border-radius: 5px;
  padding: 10px;
  transition: background 0.3s;
}

.instruction-item:hover {
  background-color: #e2ebf6;
}

.instruction-item span {
  font-size: 1em;
  color: #333;
}

@media screen and (min-width: 1600px) {
  .hint-table{width: 80%;}
  .round-tracker{width: 40%;}
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
.hint-table{width: 70%;}
.round-tracker{width: 40%;}
.instructions-popup{width: 85%;}

}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  .hint-table{width: 65%;}
  .round-tracker{width: 30%;}
  .instructions-popup{width: 85%;}
}
@media screen and (max-width: 1439px) and (min-width: 1024px) {
  .hint-table{width: 60%;}
  .round-tracker{width: 30%;}
  .instructions-popup{width: 95%;}
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .hint-table{width: 55%;}
  .round-tracker{width: 30%;}
}
</style>
