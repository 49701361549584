<template>
     <btn class="button_book_help" @click="handleClick"><font-awesome-icon class="icon" icon="question-circle"/></btn>
 </template>

<script>
export default {
    name: "Help",
    methods: {
        handleClick : function () {
             this.$router.push({name: 'Help'})
        },
    },
}
</script>

<style>
.button_book_help {
    padding: 0.5vw 0.5vw;
    border-radius: 2vw;
    font-weight: 600;
    font-size: 2.0vw;
    font-style: normal;
    color: #000000;
    cursor: pointer;
    transition: all 1s;
}

.icon{
        width: 20px;
        color: #ffffff;
    }

@media screen and (min-width: 1600px) {
        .button_book_help{
            font-size: 1.2vw;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
    .button_book_help{
        font-size: 1.5vw;
    }
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
    .button_book_help{
        font-size: 1.8vw;
    }
}
</style>
