<template>
  <section class="middle-section upgrad-screen-pg">
    <div class="container">
      <div class="subsc-pg">
        <div class="col-md-12 result-text">
          <div class="headingbar">Not enough mindcoins?</div>
          <div class="">
            Upgrade your plan to test your assets and put your brand on the map!
          </div>
        </div>
        <div class="text-heading-fx">
          <div class="card-head-bx1">Your Current Plan</div>
          <div class="card-head-bx2">Upgrade to</div>
        </div>

        <!-- here we created three licences options -->
        <div class="container_module">
          <!-- Pay as you go​  -->
          <q-card class="my-card" style="flex:2; margin-right: 3vw;">
            <q-card-section class="bg-grey-8 text-white">
              <div class="text-h6" style="text-align: center;">Pay as you go​​</div>
              <div class="text-subtitle2" style="text-align: center;">Prices start at​</div>
            </q-card-section>
            <q-card-actions vertical>
              <div style="display: flex; justify-content: center;">
                <span>$</span>
                <span class="integer">49</span>
                <span class="decimal">99</span>
              </div>
              <div style="text-align: center; margin: 1vh auto; color: grey;"> per concept </div>
              <ul>
                <div style="display: flex; align-items: center;justify-content: center;">
                  <li>Crowdsource human associations ​</li>
                </div>
                <q-separator class="horizontal"></q-separator>
                <div style="display: flex;align-items: center;justify-content: center;">
                  <li>Uncover hidden emotions​</li>
                </div>
                <q-separator class="horizontal"></q-separator>
                <div style="display: flex;align-items: center;justify-content: center;">
                  <li>Score your concepts​</li>
                </div>
              </ul>
              <div flat style="display: flex;justify-content: center;margin-top: 3vh;">
                <btn class="button_pay" @click="prompt = true">PAY PER USE​</btn>
              </div>
              <ul style="text-align: center;">
                <li style="margin-top: 3vh;">1€ = 1 mindcoin </li>
                <li>(mindcoins = credits to survey concepts)</li>
              </ul>
            </q-card-actions>
          </q-card>

          <!-- DIY Subscription​​  -->
          <q-card class="my-card" style="flex:2; margin-right: 3vw;">
            <q-card-section class="bg-grey-8 text-white">
              <div class="text-h6" style="text-align: center;">DIY Subscription​​</div>
              <div class="text-subtitle2" style="text-align: center;">Prices start at​</div>
            </q-card-section>
            <q-card-actions vertical>
              <div style="display: flex; justify-content: center;">
                <span>$</span>
                <span class="integer">99</span>
                <span class="decimal">99</span>
              </div>
              <div style="text-align: center; margin: 1vh auto; color: grey;"> per month </div>
              <ul>
                <div style="display: flex; align-items: center;justify-content: center;">
                  <li>Test your campaign assets​</li>
                </div>
                <q-separator class="horizontal"></q-separator>
                <div style="display: flex;align-items: center;justify-content: center;">
                  <li>Tune your brand story​</li>
                </div>
                <q-separator class="horizontal"></q-separator>
                <div style="display: flex;align-items: center;justify-content: center;">
                  <li>Track your brand​</li>
                </div>
              </ul>
              <div flat style="display: flex;justify-content: center;margin-top: 3vh;">
                <btn class="button_pay" @click="prompt_diy = true">BUY LICENSE​</btn>
              </div>
              <ul style="text-align: center;">
                <li style="margin-top: 3vh;">1€ = 2.5 mindcoins </li>
                <li>(min. 3 month Subscription)</li>
              </ul>
            </q-card-actions>
          </q-card>

          <!-- Do It For Me​ -->
          <q-card class="my-card" style="flex:2; ">
            <q-card-section class="bg-grey-8 text-white">
              <div class="text-h6" style="text-align: center;">Do It For Me​</div>
              <div class="text-subtitle2" style="text-align: center;">Prices start at​</div>
            </q-card-section>
            <q-card-actions vertical>
              <div style="display: flex; justify-content: center;">
                <span>$</span>
                <span class="integer">499</span>
                <span class="decimal">99</span>
              </div>
              <div style="text-align: center; margin: 1vh auto; color: grey;"> per month </div>
              <ul>
                <div style="display: flex; align-items: center;justify-content: center;">
                  <li>Benchmark all your branded assets</li>
                </div>
                <q-separator class="horizontal"></q-separator>
                <div style="display: flex;align-items: center;justify-content: center;">
                  <li>Brand equity management</li>
                </div>
                <q-separator class="horizontal"></q-separator>
                <div style="display: flex;align-items: center;justify-content: center;">
                  <li>Consulting / reporting</li>
                </div>
              </ul>
              <div flat style="display: flex;justify-content: center;margin-top: 3vh;">
                <btn class="button_pay" @click="handleContactSales">CONTACT SALES</btn>
              </div>
              <ul style="text-align: center;">
                <li style="margin-top: 3vh;">1€ = 2.5 mindcoins </li>
                <li>(includes 1 report per 3 months)</li>
              </ul>
            </q-card-actions>
          </q-card>
        </div>


        <!-- we create modal for different options -->
        <!-- this is the modal for pay as you go -->
        <q-dialog v-model="prompt" persistent>
          <q-card style="max-width: 800px">
            <q-card-section>
              <div class="q-gutter-md">
                <h4 class="text-h6" style="text-align: center; font-weight: bold;">Mindcoins Business Model​</h4>
                <div style="text-align: center;">Analyse your creative concepts / assets* using mindcoins. Invest your
                  mindcoins to survey, position and test them in Mindspeller's semantic association network.</div>
                <div class="table-container">
                  <table class="q-table q-table-dense" style=" border: 1px solid grey;">
                    <thead>
                      <tr>
                        <th></th>
                        <th class="text-center">Investment per new concept/asset</th>
                        <th class="text-center">$</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="text-align: center;">copy (eg. keyword / slogan)</td>
                        <td class="text-center">100 mindcoins</td>
                        <td class="text-center">49.99</td>
                      </tr>
                      <tr>
                        <td style="text-align: center;">image (eg. logo / website)</td>
                        <td class="text-center">200 mindcoins</td>
                        <td class="text-center">99.99</td>
                      </tr>
                      <tr>
                        <td style="text-align: center;">multimedia (eg. clip / jingle)</td>
                        <td class="text-center">300 mindcoins</td>
                        <td class="text-center">149.99</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-pt-none">
              <div> Enter the € amount you want to convert into mincoins to add your concepts / assets to the network for
                testing: </div>
              <q-input v-model="amount" outlined type="text" :rules="inputRules" dense />
            </q-card-section>
            <div style="text-align: center; background-color: #E3E9FD; margin: auto 1vw;">Mindspeller Library demo
              concepts /
              assets available for testing at 10x less Mindcoins! </div>
            <div style="text-align: center;">* digital marketing communication stimulus </div>
            <q-card-actions class="q-mb-md">
              <!-- <q-btn rounded label="Cancel" v-close-popup style="margin-left: 1vw;" />
              <q-btn rounded label="Confirm" v-close-popup :disabled="!isInputValid" @click="handlePayAsYouGo" /> -->
             
              <div flat style="display: flex;justify-content: flex-end;margin-top: 3vh;">
                <btn style="background-color:#92a0b3 !important; margin-right: 1vw;" class="button_pay" v-close-popup>
                  Cancel​</btn>
                <btn class="button_pay" v-close-popup @click="handlePayAsYouGo">Confirm​</btn>
              </div>

            </q-card-actions>

          </q-card>
        </q-dialog>

        <!-- this is the modal for diy subscriotion -->
        <q-dialog v-model="prompt_diy" persistent>
          <q-card style="max-width: 1000px;">
            <q-card-section>
              <div style="display: flex; justify-content: center;">
                <!-- here we put the 3 subscription options -->
                <q-card class="my-card" style="margin-right: 2vw;">
                  <q-card-section class="bg-grey-8 text-white">
                    <div class="text-h6" style="text-align: center;">Bronze</div>
                    <div class="text-subtitle2" style="text-align: center;">3 x $99.99</div>
                  </q-card-section>
                  <q-card-actions vertical>
                    <div class="text-subtitle2" style="text-align: center;">1€ = 2.5 mincoincs </div>
                    <div class="text-subtitle2" style="text-align: center;">(min. 3 month subscription)
                    </div>
                    <div flat v-if="this.$store.state.current_license.id != 23"
                      style="display: flex;justify-content: center;margin-top: 3vh;">
                      <btn class="button_pay" @click="changePlan(bronzeLicense)">Upgrade</btn>
                    </div>
                    <div v-else flat style="display: flex;justify-content: center;margin-top: 3vh;">
                      <btn class="button_pay" style="background-color: #92a0b3;">Bronze</btn>
                    </div>
                  </q-card-actions>
                </q-card>

                <q-card class="my-card" style="margin-right: 2vw;">
                  <q-card-section class="bg-grey-8 text-white">
                    <div class="text-h6" style="text-align: center;">Silver</div>
                    <div class="text-subtitle2" style="text-align: center;">3 x $499.99</div>
                  </q-card-section>
                  <q-card-actions vertical>
                    <div class="text-subtitle2" style="text-align: center;">1€ = 2.5 mincoincs </div>
                    <div class="text-subtitle2" style="text-align: center;">(min. 3 month subscription)
                    </div>
                    <div flat v-if="this.$store.state.current_license.id != 24"
                      style="display: flex;justify-content: center;margin-top: 3vh;">
                      <btn class="button_pay" @click="changePlan(silverLicense)">Upgrade</btn>
                    </div>
                    <div v-else flat style="display: flex;justify-content: center;margin-top: 3vh;">
                      <btn class="button_pay" style="background-color: #92a0b3;">Silver</btn>
                    </div>
                  </q-card-actions>
                </q-card>

                <q-card class="my-card">
                  <q-card-section class="bg-grey-8 text-white">
                    <div class="text-h6" style="text-align: center;">Gold</div>
                    <div class="text-subtitle2" style="text-align: center;">3 x $999.99</div>
                  </q-card-section>
                  <q-card-actions vertical>
                    <div class="text-subtitle2" style="text-align: center;">1€ = 2.5 mincoincs </div>
                    <div class="text-subtitle2" style="text-align: center;">(min. 3 month subscription)</div>
                    <div v-if="this.$store.state.current_license.id != 25" flat
                      style="display: flex;justify-content: center;margin-top: 3vh;">
                      <btn class="button_pay" @click="changePlan(goldLicense)">Upgrade</btn>
                    </div>
                    <div v-else flat
                      style="display: flex;justify-content: center;margin-top: 3vh; background-color: #92a0b3;">
                      <btn class="button_pay" style="background-color: #92a0b3;">Gold</btn>
                    </div>
                  </q-card-actions>
                </q-card>
              </div>
            </q-card-section>
            <q-card-actions class="q-mb-md">
              <btn style="background-color:#92a0b3 !important; margin-left: 1vw; width: 5vw;" class="button_pay" v-close-popup>Cancel​</btn>
              <!-- <q-btn rounded label="Cancel" v-close-popup style="margin-left: 1vw;" /> -->
            </q-card-actions>
          </q-card>
        </q-dialog>

      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue'

export default {
  name: 'UpgradeScreen',
  setup() {
    return {
      prompt: ref(false),
      prompt_diy: ref(false),
    }
  },
  data() {
    return {
      bronzeLicense: {
        "amount": 495,
        "co_limit_characters": 100,
        "credits": 1000,
        "credits_on_assignment": 1000,
        "id": 23,
        "is_demo": 0,
        "is_legacy": 0,
        "is_purchasable": 1,
        "mindcoins": 1000,
        "monthly_fee": 495,
        "name": "Bronze",
        "pay_as_you_go": 0,
        "py/object": "shared.flask_models.models.License.License"
      },
      silverLicense: {
        "amount": 985,
        "co_limit_characters": 300,
        "credits": 2000,
        "credits_on_assignment": 2000,
        "id": 24,
        "is_demo": 0,
        "is_legacy": 0,
        "is_purchasable": 1,
        "mindcoins": 2000,
        "monthly_fee": 985,
        "name": "Silver",
        "pay_as_you_go": 0,
        "py/object": "shared.flask_models.models.License.License"
      },
      goldLicense: {
        "amount": 1980,
        "co_limit_characters": 400,
        "credits": 4000,
        "credits_on_assignment": 4000,
        "id": 25,
        "is_demo": 0,
        "is_legacy": 0,
        "is_purchasable": 1,
        "mindcoins": 4000,
        "monthly_fee": 1980,
        "name": "Gold",
        "pay_as_you_go": 0,
        "py/object": "shared.flask_models.models.License.License"
      },
      amount: 250,
      allLicences: [],
      selectedLicense: null,
    };
  },
  mounted: async function () {
    document.getElementById('mindspeller').style.overflow = "auto";
    axios
      .get('/api/cas/org/license/all')
      .then((response) => (this.allLicences = response.data));
  },
  computed: {
    inputRules() {
      return [
        (v) => !!v || 'Value is required',
        (v) => !isNaN(parseFloat(v)) && isFinite(v) || 'Only numbers are allowed',
        (v) => parseInt(v) >= 1 || 'Minimum value is 1',
      ];
    },
    isInputValid() {
      const value = parseFloat(this.amount);
      return !isNaN(value) && value > 1 && !isNaN(parseFloat(value)) && isFinite(value);
    },
  },
  methods: {
    handlePayAsYouGo: function () {
      this.prompt = true;
      // here we create the license for the pay as you go option
      let license = {
        "py/object": "shared.flask_models.models.License.License",
        "id": 21, //free demo licese id 21
        "name": "Mindspeller 1.9 Playground",
        "credits": 0,
        "credits_on_assignment": 0,
        "is_legacy": 0,
        "co_limit_characters": 100,
        "monthly_fee": 0,
        "is_demo": 0,
        "is_purchasable": 1,
        "pay_as_you_go": 1,
        "amount": 0,
        "mindcoins": 0,
      }

      // here we adjust the amount based on the input
      license['amount'] = this.amount;
      license['mindcoins'] = Math.round(this.amount * 2);


      this.$router.push({
        name: 'Payment Method',
        params: {
          selectedLicense: JSON.stringify(license),
        },
      });

    },

    handleContactSales: function () {
      const mailtoLink = "mailto:contact@mindspeller.com";
      const emailLink = document.createElement("a");
      emailLink.href = mailtoLink;
      emailLink.style.display = "none";
      this.$el.appendChild(emailLink);
      emailLink.click();
      this.$el.removeChild(emailLink);
    },

    onPaymentMethodChange: function (payment_method_id) {
      this.selectedPaymentMethod = payment_method_id;
    },

    changePlan: async function (license) {
      this.selectedLicense = license;

      // here we need to do some modification on the license to align with the pay as you go
      this.selectedLicense.pay_as_you_go = 0;
      // here we adjust the amount of the corresponding fee
      if (this.selectedLicense.id == 23) {
        this.selectedLicense.amount = 299.97;
        this.selectedLicense.mindcoins = 750;
      }
      if (this.selectedLicense.id == 24) {
        this.selectedLicense.amount = 1499.97;
        this.selectedLicense.mindcoins = 3750;
      }
      if (this.selectedLicense.id == 25) {
        this.selectedLicense.amount = 2999.97;
        this.selectedLicense.mindcoins = 7500;
      }

      this.$router.push({
        name: 'Payment Method',
        params: {
          selectedLicense: JSON.stringify(this.selectedLicense),
        },
      });
    },
    handleDownGrade: function () {
      window.location = 'mailto:contact@mindspeller.com';
    },
    sortedAsc: function (items) {
      return items.sort((a, b) => {
        if (a.monthly_fee < b.monthly_fee) return -1;
        if (a.monthly_fee > b.monthly_fee) return 1;
        return 0;
      });
    },
  },
};
</script>

<style scoped>
.container_module {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1000px;
}

.integer {
  font-size: 30px;
}

.decimal {
  font-size: 12px;
  top: 0;
  left: 20px;
}

.horizontal {
  width: 89%;
  margin: 1vh auto;
  border-top: 1px solid #000;
}

.custom-icon {
  margin-right: 1vw;
  font-size: 18px;
  color: white;
  background-color: black;
  border-radius: 50%;
}

.button_pay {
  padding: 0.5vw 1.0vw;
  background: #0A00FF;
  border-radius: 2vw;
  font-style: normal;
  font-weight: 1000;
  font-size: 0.8vw;
  color: #ffffff !important;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 1s;
}

.button_pay a {
  color: #ffffff !important;
}

.button_pay:hover {
  background-color: #92a0b3;
}

.q-field__native {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}

.q-field__label {
  color: #333;
  font-weight: 500;
}
</style>




