<template>
  <teleport to="#large_modal" v-if="visible">
    <div class="overlay" @click="closeAssetPicker">
      <div
        class="modal modal-x-large ui-draggable ui-draggable-handle"
        @click.stop
      >
        <div class="modal-content">
          <div class="container modals">
            <div class="inspiration-pg library-pg">
              <div class="popup-select-asset">
                <div class="lft-col-md-1">
                  <div class="colm-1-top">
                    <h3>Select Concepts</h3>

                    <ul>
                      <li
                        v-bind:class="!active.length ? 'active' : ''"
                        v-on:click="handleActive('mindspeller')"
                      >
                        Mindspeller Library
                      </li>
                      <li
                        v-bind:class="active.length ? 'active' : ''"
                        v-on:click="handleActive('library')"
                      >
                        My Library
                      </li>
                    </ul>
                  </div>
                  <div v-if="active.length">
                    <MyLibrary
                      @clicked="onClickChild"
                      :onPositioningSelected="onPositioningSelected"
                      ref="asset-mindspeller"
                      :show_positionings="show_positionings"
                      :allowed_types="allowed_types"
                      :standalone="false"
                      :positioned_assets_only="true"
                      @assetAndPositioningSelected="
                        onAssetAndPositioningSelected
                      "
                      :isModal="true"
                    />
                  </div>
                  <div v-else>
                    <Mindspeller
                      @clicked="onClickChild"
                      :onPositioningSelected="onPositioningSelected"
                      ref="asset-library"
                      :show_positionings="show_positionings"
                      :allowed_types="allowed_types"
                      :standalone="false"
                      :positioned_assets_only="true"
                      @assetAndPositioningSelected="
                        onAssetAndPositioningSelected
                      "
                      :isModal="true"
                    />
                  </div>
                </div>
              </div>
              <div class="rgt-col-md-1">
                <div class="rightBar">
                  <sidebar
                    :show_positionings="true"
                    :asset="selected_asset"
                    :assetType="active === 'my_library' ? assetType : 'store'"
                    :onPositioningSelected="onPositioningSelected"
                    :onStoreAssetsPurchased="onStoreAssetsPurchased"
                    :value="value"
                    :fromAssetPicker="true"
                  >
                  </sidebar>
                  <ap-footer
                    v-if="active.length"
                    :selected_asset="selected_asset"
                    @close="closeAssetPicker"
                    @add="addAsset"
                  >
                  </ap-footer>
                  <store-footer
                    v-else
                    :asset="selected_asset"
                    @assetPurchased="addAsset"
                  ></store-footer>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="close" @click="closeAssetPicker">
          <img
            :src="require(`@/assets/times-light.svg`)"
            width="12"
            height="19"
          />
        </a>
      </div>
    </div>
  </teleport>
</template>

<script>
import ApFooter from './ApFooter.vue';
import Sidebar from '../asset_library/sidebar/Sidebar.vue';
// import AssetLibrary from '../asset_library/AssetLibrary.vue';
import Mindspeller from '../library/mindspeller.vue';
import MyLibrary from '../library/myLibrary.vue';
import StoreFooter from './StoreFooter.vue';

export default {
  components: {
    ApFooter,
    Sidebar,
    MyLibrary,
    Mindspeller,
    StoreFooter,
  },

  emits: ['addAsset'],

  props: {
    standalone: {
      default: false,
      type: Boolean,
    },
    suppressVideo: {
      type: Boolean,
      default: false,
    },
    positioned_assets_only: {
      type: Boolean,
      default: false,
    },
    openLibrary: {
      required: false,
      default: false,
      type: Boolean,
    },
    show_positionings: {
      default: false,
      type: Boolean,
    },
    allowed_types: {
      default: ['images', 'words', 'multimedia'],
      type: Array,
    },
  },

  data: function() {
    return {
      visible: false,
      selected_asset: {},
      selected_positioning: {},
      active: 'my_library',
      assetType: '',
      value: 'modal',
    };
  },
  mounted: function() {
    if (this.$props.openLibrary) {
      this.$refs['search-bar'].onAddNewAsset();
    }
  },
  computed: {
    // we only want to show the store in the standalone version (i.e. in
    // the Library app)
    showStore: function() {
      return this.standalone;
    },
    // only enable additional options (such as asset tracking toggle and
    // jumpstart) in the standalone version
    showAdditionalOptions: function() {
      return true;
    },
    showAddAssetButton: function() {
      return this.standalone;
    },
  },

  methods: {
    onSearch: async function(filter) {
      this.filter = filter;
    },
    onClickChild(value1, value2) {
      this.selected_asset = value1;
      this.assetType = value2;
    },

    onPositioningSelected: function(positioning) {
      this.selected_positioning = positioning;
      this.$emit(
        'assetAndPositioningSelected',
        this.selected_asset,
        this.selected_positioning
      );
    },
    onStoreAssetsPurchased: function(asset) {
      this.$refs['store-grid'].reFetchAssets(asset);
      if (this.$refs['search-bar'].assetState == 'being_positioned') {
        this.selected_asset = {};
        return;
      } else {
        this.$refs['asset-grid'].addAsset(asset);
        this.selected_asset = {};
        return;
      }
    },
    openAssetPicker: function() {
      this.visible = true;
    },
    onAssetAndPositioningSelected: function(asset, positioning) {
      this.selected_asset = asset;
      this.selected_positioning = positioning;
    },
    closeAssetPicker: function() {
      this.visible = false;
      this.selected_asset = {};
      this.active = 'my_library';
    },
    /*
            Emitting addAsset is effectively the "return function" of this
            component. It returns the selected asset and a selected
            positioning date. If the application doesn't need the positioning
            date it can just ignore that part of the payload
            */
    addAsset: function() {
      const assetId = this.selected_asset.id;
      let to = '';
      if (typeof this.selected_positioning.to !== 'undefined') {
        to = this.selected_positioning.to;
      }
      const key = `${assetId}${to}`.replace(/\W/g, '');
      const payload = {
        asset: this.selected_asset,
        positioning: this.selected_positioning,
        key: key,
      };
      console.log("image asset payload", payload);
      this.$emit('addAsset', payload);
      // this.$refs['asset-library'].resetSelection();
      // this.$refs['asset-mindspeller'].resetSelection();
      this.selected_asset = {};
    },
    handleActive: function(value) {
      if (value === 'mindspeller') {
        this.active = '';
      } else if (value === 'library') {
        this.active = 'my_library';
      }
      this.selected_asset = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.container.modals {
  padding-left: 2vw !important;
}

.search-bar {
  margin: 55px 0 0 0px;
}

.asset-workspace {
  padding: 0 5% 0 0%;
  width: 100%;
  overflow-y: auto;
}

.asset-picker-library-wrapper {
  height: 100%;
  width: 200px;
  display: flex;
}

.lft-col-md-1 {
  width: 100%;
}

.right-wrapper {
  background-color: red;
  display: flex;
  //overflow-y: auto;
}

.left-wrapper {
  display: flex;
  flex-flow: column;
  width: calc(100vw - 350px);

  @media (min-width: 1024px) {
    width: calc(100vw - #{$sidebar-small});
  }

  @media (min-width: 1600px) {
    width: calc(100vw - #{$sidebar-medium});
  }

  @media (min-width: 1920px) {
    width: calc(100vw - #{$sidebar-big});
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
}

.instrc-col {
  text-align: center;
  margin-top: 2.8vw;
}

.modal {
  // height: 95%;
  margin-top: 0px;
  // width: calc(65% + 280px);
  border-radius: 12px;
  box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 1010;
  position: relative;
  display: flex;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 46vw;
  width: 90vw;
  // height: 725px;
  // width: calc(71% + 280px);
}

.modal .form {
  margin: 20px 0 90px 0;
  height: calc(100% - 110px);
}

.modal .close {
  height: 12px;
  line-height: 12px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 20px;
  width: 12px;
  cursor: pointer;
  z-index: 200;
}

.modal.modal-large {
  height: 650px;
  width: 950px;
}

.modal.modal-s-large {
  height: 650px;
  width: 1000px;
}

.modal.modal-x-large .words-list {
  padding: 30px 30px 30px 2px;
}

.modal.modal-x-large .modal-content {
  padding: 0;
}

.modal.modal-x-large .library {
  height: 520px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  padding-left: 30px;
  margin-left: -30px;
}

.modal.modal-x-large .library .content {
  padding-right: 0;
  height: 518px;
  width: calc(100% - 260px);
}

.modal .search-bar {
  padding: 20px 0;
}

.modal .search-bar .search-form {
  width: 450px;
  max-width: 100%;
}

.modal .modal-header {
  color: #343a40;
  font-size: 1.3125rem;
  line-height: 28px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}

.modal .modal-content {
  padding: 30px 50px 110px 50px;
  position: relative;
  height: 100%;
  width: 100%;
}

.modal .modal-content .modal-content-title {
  font-size: 14px;
  font-weight: 600;
  color: #212529;
  margin: 0;
}

.modal .modal-content .selected-media {
  width: 450px;
  max-width: 100%;
  margin: 35px 0 30px 0;
}

.modal .modal-content .modal-footer {
  bottom: 0;
  height: 90px;
  left: 0;
  padding: 5px 50px 0 50px;
  position: absolute;
  width: 100%;
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: flex-end;
}

.modal .modal-content .modal-footer .btn {
  width: 150px;
}

.modal .modal-content .modal-label {
  font-size: 0.75rem;
  color: #868e96;
  line-height: 18px;
}

.modal .modal-content .modal-label img,
.modal .modal-content .modal-label span {
  vertical-align: middle;
}

.modal .modal-sidebar {
  background-color: #f7f7f7;
  flex-shrink: 0;
  padding: 20px;
  width: 280px;
  text-align: center;
  position: relative;
  z-index: 10;
}

.modal .modal-sidebar .tabs.tabs-right {
  margin-right: -20px;
}

.modal .modal-sidebar .dropdown {
  background-color: #fff;
  width: 320px;
  display: block;
  margin: 20px auto;
  max-width: 90%;
  text-align: left;
  font-size: 0.875rem;
}

.modal .modal-sidebar .dropdown .label {
  text-transform: uppercase;
  font-size: 0.875rem;
}

.modal .modal-sidebar .dropdown .dropdown-toggle {
  color: #1f1fff;
  font-weight: 700;
}

.modal .modal-sidebar .dropdown .dropdown-toggle .label {
  color: #1f1fff;
}

.modal .modal-sidebar .dropdown .dropdown-toggle .icon {
  font-size: 1rem;
}

.modal .modal-sidebar .image {
  height: 150px;
  margin: 20px 0 40px 0;
  display: block;
  position: relative;
}

.modal .modal-sidebar .image .edit {
  display: block;
  color: #fff;
  line-height: 20px;
  opacity: 0;
  -moz-transition: 400ms all ease;
  -o-transition: 400ms all ease;
  -webkit-transition: 400ms all ease;
  transition: 400ms all ease;
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal .modal-sidebar .image .edit .icon {
  font-size: 1rem;
  margin-right: 5px;
  vertical-align: middle;
}

.modal .modal-sidebar .image .edit .label {
  font-size: 0.875rem;
  vertical-align: middle;
}

.modal .modal-sidebar .image::before {
  display: block;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background-color: rgba(31, 31, 255, 0.75);
  border: solid 2px #1f1fff;
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  opacity: 0;
  -moz-transition: 400ms all ease;
  -o-transition: 400ms all ease;
  -webkit-transition: 400ms all ease;
  transition: 400ms all ease;
}

.modal .modal-sidebar .image:hover .edit,
.modal .modal-sidebar .image:hover::before {
  opacity: 1;
}

.modal .modal-sidebar .image.rounded {
  border-radius: 50%;
  // overflow: hidden;
  width: 121px;
  height: 121px;
  box-shadow: 0 2px 6px 0 rgba(31, 31, 255, 0.3);
  border: solid 2px #1f1fff;
  background-color: #fff;
  margin: 20px auto 50px;
}

.modal .modal-sidebar .image.rounded::before {
  border-radius: 50%;
}

.modal .modal-sidebar .image.rounded img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal .modal-sidebar .word {
  height: 120px;
  margin: 20px 0 40px 0;
}

.modal .modal-sidebar .word strong {
  line-height: 2.6875rem;
  font-size: 2rem;
  font-weight: bold;
  color: #343a40;
}

.modal .modal-sidebar .steps {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.modal .modal-sidebar .steps li {
  display: block;
  margin-top: 40px;
  position: relative;
}

.modal .modal-sidebar .steps li:first-child {
  margin-top: 0;
}

.modal .modal-sidebar .steps li::after {
  width: 1px;
  content: '';
  display: block;
  height: 29px;
  position: absolute;
  bottom: -35px;
  left: 50%;
  border-right: 1px dashed #ced4da;
}

.modal .modal-sidebar .steps li .num {
  background-color: #ced4da;
  color: #868e96;
  height: 17px;
  line-height: 17px;
  text-align: center;
  width: 17px;
  display: inline-block;
  border-radius: 9px;
  font-size: 0.75rem;
}

.modal .modal-sidebar .steps li .label {
  font-size: 0.875rem;
  margin: 5px 0;
}

.modal .modal-sidebar .steps li:last-child::after {
  display: none;
}

.modal .modal-sidebar .steps li.active {
  color: #1f1fff;
}

.modal .modal-sidebar .steps li.active .label {
  font-weight: bold;
}

.modal .modal-sidebar .steps li.active .num {
  background-color: #1f1fff;
  color: #fff;
}

.modal .modal-sidebar .steps li.active::after {
  border-color: #1f1fff;
}

.modal .modal-sidebar .steps li.checked .num {
  background-color: #2fd4ba;
  color: #fff;
}

.modal .modal-title {
  color: #495057;
  font-size: 0.75rem;
  line-height: 30px;
  text-transform: uppercase;
}

.modal .modal-desc {
  color: #495057;
  font-size: 0.75rem;
  margin: 0;
}

.modal textarea {
  height: 211px;
  border-radius: 6px;
  border: solid 1px #adb5bd;
  font-size: 0.875rem;
  padding: 10px;
  width: 100%;
}

.credits {
  display: flex;
  align-items: flex-end;
  line-height: 30px;
}

.credits span {
  line-height: 1.3125rem;
  font-size: 1.3125rem;
  color: #343a40;
}

.credits em {
  color: #1f1fff;
  font-size: 1.3125rem;
  line-height: 1.5rem;
  font-style: normal;
}

.credits strong {
  line-height: 1.875rem;
  font-size: 1.875rem;
  font-weight: bold;
  color: #1f1fff;
  margin-right: 5px;
}

.credits small {
  color: #868e96;
  font-size: 0.75rem;
  line-height: 0.75rem;
  vertical-align: bottom;
}

.rightBar {
  width: 100%;
  margin-top: 3vw;
  height: 80vh;
  overflow-y: auto;
}

.lft-col-md-1.scroll {
  height: 42.5vw !important;
}

.lft-col-md-1 .search-bar {
  margin-left: 1vw;
}

.rgt-col-md-1 {
  height: auto;
  min-height: 100vh;
  width: 30%;
}

#large_modal .inspiration-pg.library-pg .search-bar {
  margin-left: 0;
}


.inspiration-pg.library-pg .search-bar {
  margin-left: 1vw;
}

.library-pg .popup-select-asset {
  width: 70%;
  margin: 0;
}

.asset-data {
  margin-top: 30px !important;
}

.rightBar {
  width: 100%;
  margin-top: 1vw;
}

</style>
