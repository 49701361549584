<template>
    <div class="custom-purple-outline">
        <!-- Header with dropdown toggle -->
        <div class="info-header" @click="toggleDropdown">
            <span>{{ dropdownTitle }}</span>
            <font-awesome-icon :icon="isCollapsed ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']"
                class="toggle-icon" />
        </div>

        <!-- Dropdown Content -->
        <div v-show="!isCollapsed">
            <!-- Conditionally render asset-checkbox or dropdown contents -->
            <div v-if="this.selected_assets_and_positionings.size > 0">
                <!-- Show asset-checkbox when there are selected assets -->
                <asset-checkbox v-for="ap in selected_assets_and_positionings" :key="ap" :asset_and_positioning="ap"
                    :show_positionings="show_positionings" @removeAsset="onRemoveAsset" />
            </div>
            <div v-else>
                <!-- Show dropdown content when no assets are selected -->
                <div class="search-container">
                    <q-icon name="search" class="search-icon" />
                    <q-select use-input ref="select_widget" v-model="selectedWord" :options="options" :loading="loading"
                        :hint="hint" @filter="filter" placeholder="Search" class="q-select-no-padding">

                        <template v-slot:no-option>
                            <q-item>
                                <q-item-section class="text-grey">
                                    {{ $t('message.no_match') }}
                                </q-item-section>
                            </q-item>
                        </template>

                        <template v-slot:loading>
                            <q-item>
                                <q-item-section class="loading-container">
                                    <q-spinner size="30px" color="primary" />
                                </q-item-section>
                            </q-item>
                        </template>

                    </q-select>

                </div>
                <div v-show="!isCollapsed" class="default-words-list">
                    <div v-for="word in defaultWords" :key="word.word_id" class="default-word-item"
                        @click="selectDefaultWord(word)">
                        <q-icon name="check" class="circle-check-icon" />
                        <span class="default-word-text">{{ word.word }}</span>
                    </div>
                </div>
                <div v-show="!isCollapsed" class="lib-btn" style="margin-bottom: 20px;"><a class="lib-btn-small"
                        @click="openAssetPicker">
                        <q-icon name="expand_more" class="lib-icon-circle" /><span class="dots">...</span></a></div>
                <div v-show="!isCollapsed" class="divider"></div>
                
                <div v-show="!isCollapsed" class="image-grid-container">
                    <div v-show="!isCollapsed" class="image-grid-container">
                        <div v-show="!isCollapsed" class="image-grid">
                            <div v-for="(payload, index) in payloads" :key="payload.key" class="image-grid-item"
                                @click="emitAddAsset(payload)">
                                <img :src="payload.asset.urls.thumb_medium" :alt="payload.asset.display_name"
                                    class="grid-image" />
                            </div>
                        </div>
                    </div>

                    <!-- <div v-show="!isCollapsed" class="asset-creator-container">
                        <add-new-asset-dialog ref="add-new-asset-dialog"></add-new-asset-dialog>
                        <asset-creator @click="onAddNewAsset" :isModal="isModal"></asset-creator>
                    </div> -->
                   
                </div>
                <div v-show="!isCollapsed" class="lib-btn" style="margin-bottom: 20px;"><a class="lib-btn-small"
                        @click="openAssetPicker">
                        <q-icon name="expand_more" class="lib-icon-circle" /><span class="dots">...</span></a></div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import AddNewAssetDialog from '@/components/add_asset/AddNewAssetDialog.vue';
import AssetCreator from '@/components/asset_library/AssetCreatorDashboard.vue';
import AssetCheckbox from '@/components/target_pickers/assets/AssetCheckboxDashboard.vue';

import {
    alert_error,
    genericDialog
} from '@/helpers/alert_helper.js';

export default {
    components: {
        AddNewAssetDialog, AssetCreator, AssetCheckbox
    },
    emits: ['wordSelected', 'wordChanged', 'assetClick', 'addNewAsset', 'addAsset'],
    props: {
        clearAfterSelection: {
            default: true,
            type: Boolean,
        },
        hint: {
            default: '',
            type: String,
        },
        standalone: {
            default: false,
            type: Boolean,
        },
        selected_assets_and_positionings: {
            type: Set,
            required: true,
        },
        show_positionings: {
            type: Boolean,
            required: true,
        },
        openAssetPicker: Function,
        onAddNewAsset: Function,
        onRemoveAsset: Function,
        show_positionings: { default: false, type: Boolean },
        allowed_types: { default: ['images', 'words', 'multimedia'], type: Array },
        isModal: {
            type: String,
        },
        background: {
            required: false,
            type: String,
            default: 'img-icon.png',
        },
    },
    data() {
        return {
            selectedWord: null,
            options: new Array(),
            isCollapsed: true,
            loading: false,
            imageUrl: '',
            last_search: new String(),
            defaultWordData: {
                COURAGE: {
                    value: {
                        word: "COURAGE",
                        created_at: "2020-12-03T14:45:15",
                        updated_at: "2020-12-03T14:45:18",
                        asset_mime_type: null,
                        file_identifier: null,
                        external: 0,
                        word_id: 4741,
                        type_: {
                            id: 1,
                            title: "text",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: false,
                            isImage: false,
                            isAudio: false,
                            isVideo: false,
                        },
                        image_url: "",
                        has_access: false,
                    },
                    label: "COURAGE",
                },
                TASTE: {
                    value: {
                        word: "TASTE",
                        created_at: "2020-12-03T14:45:15",
                        updated_at: "2020-12-03T14:45:18",
                        asset_mime_type: null,
                        file_identifier: null,
                        external: 0,
                        word_id: 20331,
                        type_: {
                            id: 1,
                            title: "text",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: false,
                            isImage: false,
                            isAudio: false,
                            isVideo: false,
                        },
                        image_url: "",
                        has_access: false,
                    },
                    label: "TASTE",
                },
                AUTHENTIC: {
                    value: {
                        word: "AUTHENTIC",
                        created_at: "2020-12-03T14:45:15",
                        updated_at: "2020-12-03T14:45:18",
                        asset_mime_type: null,
                        file_identifier: null,
                        external: 0,
                        word_id: 1481,
                        type_: {
                            id: 1,
                            title: "text",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: false,
                            isImage: false,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: "",
                        has_access: false
                    },
                    label: "AUTHENTIC"
                }
            },
            // List of default words to display in the UI
            defaultWords: [
                { word: 'AUTHENTIC', word_id: 1481 },
                { word: "COURAGE", word_id: 4741 },
                { word: "TASTE", word_id: 20331 },
            ],
            payloads: [
                {
                    asset: {
                        id: 1935,
                        state: 2,
                        brand: 0,
                        display_name: "shutterstock_1284088828",
                        top_associations:
                            "SINGING, SING, KARAOKE, FRIENDS, MIKE, FUN, GIRLS, PARTY, MUSIC, FRIEND, FRIENDSHIP, MICROPHONE, DUET, ENJOY, ENTERTAINMENT, SONG, DRINK, LAUGHTER, JOY, HAPPY",
                        labels: "SMILE,TARTAN,FASHION,TEXTILE,FLASH PHOTOGRAPHY",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 5.66232,
                        total_count: 15,
                        word: {
                            word: "SHUTTERSTOCK_1284088828",
                            created_at: "2021-10-12T16:03:03",
                            updated_at: "2021-10-12T16:03:03",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                            external: 1,
                            word_id: 312740,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                        },
                    },
                    positioning: {
                        id: 502,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>The concepts or stimuli exposed to you can comprise imagery, words, multimedia, logos, symbols, etc.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typos.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-10-26",
                        url_slug: "mdsp_auto115",
                        redirect_url: "",
                        created_at: "2021-10-12T16:03:05",
                        updated_at: "2021-11-25T18:55:41",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-10-12",
                    },
                    key: "193520211026",
                },
                {
                    asset: {
                        id: 1379,
                        state: 2,
                        brand: 0,
                        display_name: "583571431",
                        top_associations:
                            "HAPPY, KIDS, FLOWERS, CHILDREN, SPRING, GRASS, FLOWER, FUN, SUMMER, NATURE, DAISY, DAISIES, CHILDHOOD, SMILE, HAPPINESS, CHILD, GARDEN, FRIENDS, BRIGHT, OUTDOORS",
                        labels: "SMILE,HAND,PLANT,FLOWER,GREEN",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 6.66167,
                        total_count: 89,
                        word: {
                            word: "583571431",
                            created_at: "2021-04-12T11:24:46",
                            updated_at: "2021-04-12T11:24:46",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "792e6221-5947-41a1-aa7f-a3d23fc5843e",
                            external: 1,
                            word_id: 309249,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "792e6221-5947-41a1-aa7f-a3d23fc5843e",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_792e6221-5947-41a1-aa7f-a3d23fc5843e",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_792e6221-5947-41a1-aa7f-a3d23fc5843e",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_792e6221-5947-41a1-aa7f-a3d23fc5843e",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_792e6221-5947-41a1-aa7f-a3d23fc5843e",
                        },
                    },
                    positioning: {
                        id: 438,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typo's.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-04-20",
                        url_slug: "mdsp_auto82",
                        redirect_url: "",
                        created_at: "2021-04-12T11:24:46",
                        updated_at: "2021-04-23T16:47:19",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-04-12",
                    },
                    key: "137920210420",
                },
                {
                    asset: {
                        id: 1947,
                        state: 2,
                        brand: 0,
                        display_name: "shutterstock_1938550555",
                        top_associations:
                            "ART, HOME, COUPLE, FAMILY, FRAME, DECOR, PICTURE, WALL, HANG, PAINTING, SOFA, LOVE, PHOTO, NEW HOME, DECORATING, MAN, HOUSE, DESIGN, WOMEN, HAPPY",
                        labels: "FURNITURE,PROPERTY,COMFORT,INTERIOR DESIGN,WALL",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 4.40072,
                        total_count: 25,
                        word: {
                            word: "SHUTTERSTOCK_1938550555",
                            created_at: "2021-10-12T16:03:03",
                            updated_at: "2021-10-12T16:03:03",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "894c6a24-4c28-4e17-b163-99b625ecbddf",
                            external: 1,
                            word_id: 312752,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "894c6a24-4c28-4e17-b163-99b625ecbddf",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_894c6a24-4c28-4e17-b163-99b625ecbddf",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_894c6a24-4c28-4e17-b163-99b625ecbddf",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_894c6a24-4c28-4e17-b163-99b625ecbddf",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_894c6a24-4c28-4e17-b163-99b625ecbddf",
                        },
                    },
                    positioning: {
                        id: 502,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typo's.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-10-26",
                        url_slug: "mdsp_auto115",
                        redirect_url: "",
                        created_at: "2021-10-12T16:03:05",
                        updated_at: "2021-11-25T18:55:41",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-10-12",
                    },
                    key: "194720211026",
                },
                {
                    asset: {
                        id: 1915,
                        state: 2,
                        brand: 0,
                        display_name: "shutterstock_635611256",
                        top_associations:
                            "DRINK, BAR, BARTENDER, COCKTAIL, ALCOHOL, DRINKS, WINE, MARTINI, PARTY, MAN, GLASS, TASTY, MIX, MIXOLOGY, FUN, DRUNK, FILTER, RELAXING, YUMMY, MIXOLOGIST",
                        labels: "TABLEWARE,DRINKWARE,MARTINI GLASS,STEMWARE,LIQUID",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 4.52654,
                        total_count: 2,
                        word: {
                            word: "SHUTTERSTOCK_635611256",
                            created_at: "2021-10-12T16:03:03",
                            updated_at: "2021-10-12T16:03:03",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "d165f412-6928-4e35-afcd-ddfd966e88bc",
                            external: 1,
                            word_id: 312720,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "d165f412-6928-4e35-afcd-ddfd966e88bc",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_d165f412-6928-4e35-afcd-ddfd966e88bc",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_d165f412-6928-4e35-afcd-ddfd966e88bc",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_d165f412-6928-4e35-afcd-ddfd966e88bc",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_d165f412-6928-4e35-afcd-ddfd966e88bc",
                        },
                    },
                    positioning: {
                        id: 500,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typo's.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-10-26",
                        url_slug: "mdsp_auto113",
                        redirect_url: "",
                        created_at: "2021-10-12T16:03:04",
                        updated_at: "2021-11-05T17:48:39",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-10-12",
                    },
                    key: "191520211026",
                },
                {
                    asset: {
                        id: 1079,
                        state: 2,
                        brand: 0,
                        display_name: "373369408",
                        top_associations:
                            "BUSINESS, CITY, SUCCESS, SUIT, MAN, TOP, BUSINESSMAN, WORK, CLOUD, PROFESSIONAL, MOUNTAIN, BRIEFCASE, ROCK, WEIRD, CORPORATE, SMART, POWER, SKYSCRAPER, BUILDINGS, CAREER",
                        labels: "BUILDING,WORLD,STANDING,ATMOSPHERIC PHENOMENON,FLASH PHOTOGRAPHY",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 4.78032,
                        total_count: 46,
                        word: {
                            word: "373369408",
                            created_at: "2021-02-01T13:58:00",
                            updated_at: "2021-02-01T13:58:00",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                            external: 0,
                            word_id: 306987,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                        },
                    },
                    positioning: {
                        id: 381,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typo's.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-02-08",
                        url_slug: "mdsp_auto59",
                        redirect_url: "",
                        created_at: "2021-02-01T13:58:02",
                        updated_at: "2021-05-14T15:41:08",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-02-01",
                    },
                    key: "107920210208",
                },
                {
                    asset: {
                        id: 724,
                        state: 2,
                        brand: 0,
                        display_name: "1320625385",
                        top_associations:
                            "BOOK, TREE, KNOWLEDGE, LIBRARY, BOOKS, READING, NATURE, LIFE, LEARNING, BONSAI, GROWTH, BRIGHT, BROCCOLI, EDUCATION, GREEN, READ, PEACE, GROW, SUN, LEARN",
                        labels: "PUBLICATION,WOOD,BOOK,LINE,BOOKCASE",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 6.42149,
                        total_count: 40,
                        word: {
                            word: "1320625385",
                            created_at: "2021-01-20T13:00:57",
                            updated_at: "2021-01-20T13:00:57",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                            external: 0,
                            word_id: 302902,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                        },
                    },
                    positioning: {
                        id: 355,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typo's.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-01-27",
                        url_slug: "mdsp_auto33",
                        redirect_url: "",
                        created_at: "2021-01-20T13:00:59",
                        updated_at: "2021-02-12T15:17:10",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-01-20",
                    },
                    key: "72420210127",
                },
            ],
        };
    },
    computed: {
        // we only want to show the store in the standalone version (i.e. in
        // the Library app)
        showAddAssetButton: function () {
            return this.standalone;
        },
        dropdownTitle() {
            // If selected assets exist, display the first asset's name, otherwise default to "Select your value"
            return this.selected_assets_and_positionings.size > 0
                ? "Selected value"
                : "Select your value";
        },

    },

    methods: {
        reset: function () {
            this.selectedWord = null;
        },
        toggleDropdown() {
            this.isCollapsed = !this.isCollapsed;
        },
        filter: async function (val, update, abort) {
            await update(this.fetchWords(val));
        },

        fetchWords: async function (val) {
            if (val === '') {
                this.options = [];
                this.last_search = val;
            } else {
                if (val !== this.last_search) {
                    this.loading = true;
                    const payload = {
                        draw: 1,
                        search: {
                            value: val.toLowerCase(),
                        },
                        start: 0,
                        length: 8,
                    };

                    const response = await axios.post('/api/cas/words/search', payload);
                    const options = response.data.data.map((w) => ({
                        value: w,
                        label: w.has_access ? `✔ ${w.word}` : w.word,
                    }));
                    this.options = options;
                    this.last_search = val;
                    this.$refs.select_widget.showPopup();
                    this.loading = false;

                }
            }
        },

        selectDefaultWord(word) {
            const wordData = this.defaultWordData[word.word];
            if (wordData) {
                // Directly call the watcher function with the entire `new_value` structure
                this.selectedWord = wordData; // This simulates triggering the watcher
            }
        },
        onAddNewAsset: function () {
            this.$refs['add-new-asset-dialog'].open();
        },
        emitAddAsset(payload) {
            this.$emit("addAsset", payload);
        },

        iaSelect: async function (word_object) {
            const wordKey = word_object.word;

            if (this.defaultWordData[wordKey]) {
                // Use embedded data for default words without API call
                this.$emit('wordSelected', word_object);
            } else {
                const payload = {
                    word_ids: [word_object.word_id],
                };
                // here we remove the charging part
                const access = await axios.post('/api/cas/words/check_access', payload);
                if (access.data[word_object.word_id]) {
                    this.$emit('wordSelected', word_object);
                } else {
                    const price = this.$store.getters.get_price('public_word_asset');
                    const dialog_options = {
                        title: this.$t('message.purchase_ia', {
                            word: word_object.word,
                        }),
                        text: this.$t('message.ia_cost', {
                            price: price,
                        }),
                        confirmButtonText: this.$t('message.add'),
                        cancelButtonText: this.$t('message.cancel'),
                    };
                    const dialog_result = await genericDialog(dialog_options);
                    if (dialog_result.isConfirmed) {
                        const url = `/api/cas/words/request_access_new`;
                        const response = await axios.post(url, {
                            word_ids: [word_object.word_id],
                        });
                        if (response.data.access_granted) {
                            this.$emit('wordSelected', word_object);
                            this.$store.dispatch('get_mindcoin_balance');
                        }
                        if (response.data.insufficient_credits) {
                            alert_error(this.$t('message.insufficient_credits_for_ia'));
                        }
                        if (response.data.read_only) {
                            alert_error(this.$t('message.read_only_user_no_ia'));
                        }
                    }
                }
            }
        },

    },
    watch: {
        selectedWord: function (new_value) {
            if (new_value !== null) {
                this.iaSelect(new_value.value);
                if (this.clearAfterSelection) {
                    this.reset();
                }
            } else {
                this.$emit('wordChanged', new_value);
            }
        },
    },
};
</script>

<style scoped>
.loading-container {
    display: flex;
    justify-content: center;
    margin-left: -18px;

}

.search-container {
    display: flex;
    align-items: center;
    position: relative;
}

.custom-purple-outline {
    background-color: #f9fbfd;
    border: 1px solid #7878e9;
    border-radius: 8px;
    padding: 5px;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    transition: max-height 0.3s ease;
    margin-left: auto;
    margin-right: auto;
}

.info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 15px; */
    font-size: 1em;
    font-weight: bold;
    border: 1px solid #eef3fa;
    border-radius: 8px;
    color: #333;
    background-color: #eef3fa;
    cursor: pointer;
}

.toggle-icon {
  color: #000000;
  transition: transform 0.3s;
}

.info-header:hover {
    background-color: #a2c9ff;
}

.header-icon {
    color: #7878e9;
    transition: transform 0.3s;
}

::v-deep .q-field--outlined.q-field__control {
    border-color: #9a9a9f !important;
}

.lib-btn {
    position: relative;
    display: flex;
    margin-top: 10px;
    justify-content: left;
    width: 20%;
    
}

.divider {
    height: 1px;
    background-color: #ddd;
    width: 95%;
    margin: 15px;
    /* Space around the divider */
}


.lib-btn-small {
    /* background-color: #0000ff; */
    color: black;
    width: 15%;
    margin-left: 2%;
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 14px;
    text-align: left;
    text-decoration: none;
    display: flex;
    cursor: pointer;
    transition: background-color 0.3s;
}

.lib-btn:hover {
    transform: scale(1.05);
    border-radius: 15px;
    background-color: #a2c9ff;
}

.lib-icon-circle {
    position: absolute;
    left: 10px;
    margin-right: 10%;
    font-size: 16px;
    /* Adjust size as needed */
    color: black;
    /* Icon color */
    background-color: transparent;
    /* Transparent background */
    border: 2px solid black;
    /* Black border */
    border-radius: 50%;
    /* Circular shape */
    width: 16px;
    /* Circle size */
    height: 16px;
    /* Circle size */
    display: flex;
    align-items: center;
    justify-content: center;
}

.dots {
    margin-left: 10px;
    /* Adjust spacing as needed */
    color: black;
}

.search-icon {
    color: #9a9a9f;
    /* Adjust color to match the style */
    margin-right: auto;
    margin-left: 8px;
    margin-bottom: 15px;
    font-size: 20px;
    /* Space between the icon and placeholder */
}

/* Default words styling */

.default-words-list {
    display: flex;
    flex-direction: column;
}

.default-word-item {
    display: flex;
    align-items: center;
    padding: 8px;
    /* background-color: #f0f0f0; */
    border-radius: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
    width: 95%;
    overflow-y: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.default-word-item:hover {
    background-color: #a2c9ff;
    transform: scale(1.02);
}

.default-word-text {
    flex: 1;
    font-size: 10px;
    color: #333;
}

.circle-check-icon {
    background-color: black;
    /* Black circle */
    color: white;
    /* White checkmark */
    border-radius: 50%;
    /* Make it circular */
    padding: 2px;
    /* Space around the checkmark */
    font-size: 8px;
    /* Adjust size as needed */
    margin-right: 5%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.image-grid-container {
    display: flex;
    align-items: center;
    /* Align items at the top */
    justify-content: center;
    gap: 10px;
    margin-bottom: auto;
    z-index: 1;
    /* Space between grid and asset creator */
}

.asset-creator-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* Center asset creator vertically with the grid */
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(2, 80px);
    /* 3 columns */
    grid-template-rows: repeat(3, 50px);
    /* 2 rows */
    column-gap: 1px;
    row-gap: 18px;
    margin-top: 5px;
    /* margin-left: 8%; */
    justify-items: start;
    /* Align items to the left of the grid */
    align-items: start;
}

.image-grid-item {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;

    background-color: #f0f0f0;
    cursor: pointer;
    /* Makes the images look clickable */
    transition: transform 0.2s ease-in-out;
}

.image-grid-item:hover {
    transform: scale(1.55);
    /* Slight zoom effect on hover */
}

.grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    /* Ensures the image covers the grid item while maintaining aspect ratio */
}

/* Media Queries */
@media screen and (min-width: 1600px) {
    .custom-purple-outline {
        width: 105%;
    }

    .search-icon {
        color: #9a9a9f;
        /* Adjust color to match the style */
        margin-right: -15px;
        margin-left: 8px;
        margin-bottom: 15px;
        font-size: 20px;
        /* Space between the icon and placeholder */
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(2, 115px);
        /* 3 columns */
        grid-template-rows: repeat(3, 65px);
        /* 2 rows */
        column-gap: 5px;
        row-gap: 5px;
        margin-top: 5px;
        /* margin-left: 5%; */
        justify-items: start;
        /* Align items to the left of the grid */
        align-items: start;
    }

}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
    .custom-purple-outline {
        width: 105%;
    }

    .search-icon {
        color: #9a9a9f;
        /* Adjust color to match the style */
        margin-right: -15px;
        margin-left: 8px;
        margin-bottom: 15px;
        font-size: 20px;
        /* Space between the icon and placeholder */
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(2, 110px);
        /* 3 columns */
        grid-template-rows: repeat(3, 60px);
        /* 2 rows */
        column-gap: 1px;
        row-gap: 8px;
        margin-top: 1px;
        /* margin-left: 5%; */
        justify-items: start;
        /* Align items to the left of the grid */
        align-items: start;
    }

}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
    .custom-purple-outline {
        width: 112%;
    }

    .search-icon {
        color: #9a9a9f;
        /* Adjust color to match the style */
        margin-right: -18px;
        margin-left: 8px;
        margin-bottom: 15px;
        font-size: 20px;
        /* Space between the icon and placeholder */
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(2, 90px);
        /* 3 columns */
        grid-template-rows: repeat(3, 40px);
        /* 2 rows */
        column-gap: 1px;
        row-gap: 8px;
        margin-top: 1px;
        margin-left: 5%;
        justify-items: start;
        /* Align items to the left of the grid */
        align-items: start;
    }
}
</style>
