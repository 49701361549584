import { createRouter, createWebHashHistory } from 'vue-router';
import { isLoggedIn } from '@/helpers/login_helper.js';
import Redirecting from '@/components/dashboard/Redirecting.vue'
import Dashboard from '@/components/network_visualisation/Network.vue';
import Mindspeller_Network from '@/components/b2c_network_visualisation/Network.vue'
import Login from '@/components/login/Login';
import FreeTrial from '@/components/login/FreeTrial';
import Help from '@/components/dashboard/index';
import Dashboard_new from '@/components/dashboard/index_new';
import LandingPage from '@/components/dashboard/LandingPage';
import Archetypes from '@/components/dashboard/Archetypes';
import Passions from '@/components/dashboard/Passions';
import BrandValue from '@/components/dashboard/BrandValue';
import Result from '@/components/result/index.vue';
import InspirationExplorer from '@/components/inspiration_explorer/index.vue';
import AssociationWheel from '@/components/association_wheel/index.vue';
import Mindbias from '@/components/mindbias/index.vue';
import Library from '@/components//library/index.vue';
import Library_Mindspeller from '@/components//library/index_mindspeller.vue';
import ConnectionChecker from '@/components/connection_checker/index.vue';
import CompetitiveProfiler from '@/components/competitive_profiler/index.vue';
import StoryCreator from '@/components/story_creator/index.vue';
import ContentTuner from '@/components/content_tuner/index.vue';
import BrandCampaign from '@/components/brand_campaign/index.vue';
import UpgradeScreen from '@/components/payment/upgrade_screen.vue';
import SubscriptionPlans from '@/components/payment/subscription_plans.vue';
import MyAccount from '@/components/my_account/index.vue';
import MyBrand from '@/components/my_brand/index.vue';
import OrderSummary from '@/components/payment/order_summary.vue';
import BillingInfo from '@/components/payment/billing_info.vue';
import PaymentSuccess from '@/components/payment/payment_success.vue';
import BrandTracker from '@/components/brand_tracker/index.vue';
import PasswordSetup from '@/components/login/PasswordSetup.vue';
import ForgotPassword from '@/components/login/ForgotPassword.vue';
import Payment from '@/components/payment/payment.vue';
import Team from "@/components/my_account/team.vue";
import Invite from "@/components/my_account/invite.vue";
import TeamUserEdit from "@/components/my_account/edit.vue";
import ChangePassword from "@/components/my_account/changePassword.vue";
import License from "@/components/my_account/license.vue";
import SurveyCleaning from '@/components/survey_cleaning/index.vue';
import Maintenance from '@/components/maintenance/maintenance.vue';
import ConceptOnboarding from '../components/dashboard/ConceptOnboarding.vue';
import BrandConceptOnboarding from '../components/dashboard/BrandConceptOnboarding.vue';

const routes = [
  {
    path:'/dashboard',
    name:'Dashboard',
    component:Dashboard,
  },
  {
    path:'/mindspeller_network',
    name:'Mindspeller_Network',
    component:Mindspeller_Network,
  },
  {
    path: '/free_trial',
    name: 'Free Trial',
    component: FreeTrial,
  },
  {
    path: '/password_step',
    name: 'Password Setup',
    component: PasswordSetup,
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgot_password',
    name: 'Forgot Password',
    component: ForgotPassword,
  },
  {
    path:'/redirecting',
    name:'Redirecting',
    component: Redirecting,
  },
  {
    name: 'Archetypes',
    path: '/archetypes',
    props: true,
    component: Archetypes,
  },
  {
    name: 'Passions',
    path: '/passions',
    props: true,
    component: Passions,
  },
  {
    name: 'Brand Value',
    path: '/brand_value',
    props: true,
    component: BrandValue,
  },
  {
    path: '/result',
    name: 'Result',
    component: Result,
  },
  {
    path: '/brand_tracker',
    name: 'Brand Tracker',
    component: BrandTracker,
  },
  {
    path: '/inspiration-explorer',
    name: 'Inspiration Explorer',
    component: InspirationExplorer,
  },
  {
    path: '/association-wheel',
    name: 'Association Wheel',
    component: AssociationWheel,
  },
  {
    path: '/mindbias',
    name: 'Mindbias',
    component: Mindbias,
  },
  {
    path: '/order_summary',
    name: 'Order Summary',
    component: OrderSummary,
  },
  {
    path: '/payment_method',
    name: 'Payment Method',
    props: true,
    component: Payment,
  },
  {
    path: '/billing_info',
    name: 'Billing Information',
    component: BillingInfo,
  },
  {
    path: '/payment_success',
    name: 'Payment Success',
    props: true,
    component: PaymentSuccess,
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
  },
  {
    path: '/dashboard_new',
    name: 'Dashboard_new',
    component: Dashboard_new,
  },
  {
    path: '/content_tuner',
    name: 'Content Tuner',
    component: ContentTuner,
  },
  {
    path: '/brand_campaign',
    name: 'Brand Campaign',
    component: BrandCampaign,
  },
  {
    path: '/upgrade_screen',
    name: 'Upgrade Screen',
    component: UpgradeScreen,
  },
  {
    path: '/survey-cleaning',
    name: 'Survey Cleaning',
    component: SurveyCleaning,
  },
  {
    path: '/my-account',
    name: 'My Account',
    component: MyAccount,
  },
  {
    name: 'Team',
    path: '/team',
    component: Team,
  },
  {
    name: 'Invite a team member',
    path: '/team/invite',
    component: Invite,
  },
  {
    name: 'Maintenance',
    path: '/maintenance',
    component: Maintenance,
  },
  {
    path: '/concept-onboarding',
    name: 'Concept Onboarding',
    component: ConceptOnboarding,
  },
  {
    path: '/brand-concept-onboarding',
    name: 'Brand Concept Onboarding',
    component: BrandConceptOnboarding,
  },
  {
    name: 'Edit a team member',
    path: '/team/edit',
    component: TeamUserEdit,
    props: (route) => {
      // we need to cast type and active to int and bool because
      // params passed to $router.push() always end up as strings
      return {
        id: parseInt(route.params.id),
        username: route.params.username,
        email: route.params.email,
        type: parseInt(route.params.type),
        active: route.params.active == "true" ? true : false,
      }
    }
  },
  {
    name: 'Change password',
    path: '/change_password',
    component: ChangePassword,
  },
  {
    name: 'License',
    path: '/license',
    component: License,
  },
  {
    path: '/my-brand',
    name: 'My Brand',
    component: MyBrand,
  },
  {
    path: '/story_creator',
    name: 'Story Creator',
    component: StoryCreator,
  },
  {
    path: '/competitive_profiler',
    name: 'Competitive Profiler',
    component: CompetitiveProfiler,
  },
  {
    path: '/connection_checker',
    name: 'Connection Checker',
    component: ConnectionChecker,
  },
  {
    path: '/subscription_plans',
    name: 'Subscription Plans',
    component: SubscriptionPlans,
    props: true,
  },
  {
    path: '/library',
    name: 'Library',
    component: Library,
    props: true,
  },
  {
    path: '/library_mindspeller',
    name: 'Library_Mindspeller',
    component: Library_Mindspeller,
    props: true,
  },

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

/*
 * Every time we route to a page we check if the user is logged in. If the user
 * is not logged in we redirect them to the free trial page.
 */
router.beforeEach((to, from, next) => {
  const isAnonymous = localStorage.getItem('is_anonymous');

  if (to.path === '/' && isAnonymous==null) {
    next('/dashboard');
  }
  if(to.path === '/login' && isAnonymous){
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('jwt_refresh_token');
    localStorage.removeItem('is_anonymous');
    next('/mindspeller_network');
    return axios.post('/api/cas/token/refresh_for_network', {}).then(response => {
      localStorage.setItem('jwt_token', response.data['x-jwt-access-token']);
      localStorage.setItem('jwt_refresh_token', response.data['x-jwt-refresh-token']);
      localStorage.setItem('is_anonymous', 'true');

    });
  }
  // publicPages is a list of routes that are accessible even if a user is not
  // logged in
  const publicPages = [
    '/free_trial',
    '/mindspeller_network',
    '/login',
    '/sign_up',
    '/sign_up/onboarding',
    '/password_step',
    '/forgot_password',
    '/maintenance',
  ];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !isLoggedIn()) {
    next('/free_trial');
  }
  else if (!authRequired && isLoggedIn() && isAnonymous==null) {
    next('/dashboard');
  }
  else {
    next();
  }
});

export default router;
