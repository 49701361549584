<template>
    <div class="header-css"> 
        <div class="main-menu">  
            <v-sheet class="first-main-menu-item"><Logo /></v-sheet>
            <v-sheet class="main-menu-item"><Home/></v-sheet>
            <v-sheet class="main-menu-item"><Library/></v-sheet>
            <v-sheet class="main-menu-item"><MyBrand /></v-sheet>
            <v-sheet class="main-menu-item"><Tools/></v-sheet>
        </div>
        <div class= "user-related-menu">
            <v-sheet class="main-menu-item"><Organization /></v-sheet>
            <v-sheet class="main-menu-item"><TestConcepts/></v-sheet>
            <v-sheet class="main-menu-item"><Tour/></v-sheet>
            <v-sheet class="main-menu-item"><Help/></v-sheet>
            <v-sheet class="main-menu-item"><UserIcon /></v-sheet>
            <v-sheet class="main-menu-item"><Credits /></v-sheet>
        </div>
    </div>
</template>

<script>
import Home from './Home.vue';
import Help from './Help.vue';
import Credits from './Credits.vue';
import Library from './Library.vue';
import Logo from "./Logo.vue";
import MyBrand from "./MyBrand.vue";
import Organization from './Organization.vue';
import Tools from './Tools.vue';
import Tour from "./Tour.vue";
import UserIcon from './UserIcon.vue';
import TestConcepts from './TestConcepts.vue';



export default {
    name: "Header",
    components: {
        Home,
        Logo,
        Tools,
        Library,
        MyBrand,
        Organization,
        Tour,
        Help,
        UserIcon,
        Credits,
        TestConcepts
    },
    props: {
        showHeaderDropDown: Boolean
    }
}
</script>

<style scoped>
    .header-css{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 75px;
        background-color: #7878E9;
        box-shadow: 0 0.1vw 0.7vw #d3d3d3d9;
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        color: white;
    }
    .main-menu{
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        height: 75px;
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
    }
    .main-menu-item{
        flex-basis: auto;
        margin-right: 10px;
        margin-left: 10px;
    }

    .first-main-menu-item{
        flex-basis: auto;
        margin-right: 10px;
    }

    .user-related-menu{
        display: flex;
        justify-content: flex-end;
        gap: 0px;
        height: 75px;
        position: fixed;
        top: 0;
        right: 0;
        align-items: center;
        margin-right: 10px;
    }
</style>