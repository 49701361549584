<template>
  <div id="app">
    <!-- Custom Sidebar for displaying the node information -->
    <div class="sidebar-wrapper" v-if="wordData && Object.keys(wordData).length">
      <Sidebar :wordData="wordData" />
    </div>
    <!-- Grouped Instructions Button and Pointing Label -->
    <div class="instructions-wrapper">
      <button class="instructions-button" @click="openInstructionsPopup">
        <font-awesome-icon :icon="['fas', 'question']" class="header-icon" />
      </button>
      <div v-if="this.showInstructionPopup" class="pointing-label">Read Me!</div>
    </div>

    <!-- Instructions Popup -->
    <InstructionsPopup ref="instructionsPopup" />

    <!-- TensorBoard iframe embedded -->
    <iframe
      src="https://tensorboard.mindspeller.com/#projector"
      id="tensorboard-frame"
    ></iframe>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import Sidebar from "./Sidebar.vue";
import InstructionsPopup from "./InstructionsPopup.vue";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    Sidebar,
    InstructionsPopup,
  },
  computed: {
    ...mapGetters(["showInstructionPopup"]), // Getter for instruction popup visibility
  },
  data() {
    return {
      index: null, // Index selected from TensorBoard
      wordData: null, // Stores word data received from API
      label: null,
    };
  },
  methods: {
    openInstructionsPopup() {
      this.$refs.instructionsPopup.openPopup();
    },
    handleIframeMessage(event) {
      if (event.data.type === "tensorboard-label") {
        const sidebar_selection = event.data.data;
        if (sidebar_selection) {
          this.label = sidebar_selection;
          this.get_label();
        } else {
          console.log("Sidebar selection is empty");
        }
      } else if (event.data.type === "clear-selection") {
        console.log("Clear selection received");
        this.wordData = null;
      }
    },
    async get_label() {
      let payload = {
        label: this.label,
      };
      try {
        // Send POST request to get the word at the specified index
        const response = await axios.post(
          "/api/cas/survey_cleaning/projector",
          payload
        );
        let wordData = response.data;
        this.wordData = wordData;
      } catch (error) {
        console.error("Error during API call:", error);
      }
    },
    showAlert(message) {
      alert(message);
    },
  },
  watch: {
    wordData(newVal, oldVal) {
      if (!oldVal && newVal && Object.keys(newVal).length) {
        // Trigger alert when wordData transitions from null to full
        this.showAlert('Click "ISOLATE" to continue. Use the slider to limit the neighbors.');
      }
    },
  },
  mounted() {
    // Add event listener for messages from the iframe
    // this.openInstructionsPopup();
    window.addEventListener("message", this.handleIframeMessage);
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed
    window.removeEventListener("message", this.handleIframeMessage);
  },
};
</script>

<style>
iframe {
  width: 100%;
  height: 100vh;
  border: none;
}

.sidebar-wrapper {
  position: fixed;
  top: 10%;
  left: 0;
  width: 22%;
  height: calc(100% - 60px);
  z-index: 2;
}

/* Wrapper for Instructions Button and Label */
.instructions-wrapper {
  position: fixed;
  top: 20%; /* Adjust this for vertical alignment */
  left: 22%; /* Adjust this for horizontal alignment */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; /* Space between the button and the label */
  z-index: 4;
}

.icon {
  font-size: 1.5em;
  color: white;
  pointer-events: none;
}

.instructions-button {
  width: 30px;
  height: 30px;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 15px;
   display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.instructions-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.instructions-button:active {
  transform: scale(1);
}

.pointing-label {
  display: inline-block !important;
  background-color: #7878e9;
  color: black;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: bounce 1.5s infinite;
  margin-left: 10px; /* Ensures spacing from the button */
  position: relative;
}

/* Pointing Arrow */
.pointing-label::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%; /* Position arrow on the left side */
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent #7878e9 transparent transparent;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@media screen and (min-width: 1600px) {
  .instructions-wrapper {
  position: fixed;
  top: 19.5%;
  left: 17%;
  /* margin-left: 10px; */

}
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .instructions-wrapper {
  position: fixed;
  top: 27%;
  left: 20%;
  /* margin-left: 10px; */

}
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  .instructions-wrapper {
  position: fixed;
  top: 29%;
  left: 23%;
  /* margin-left: 10px; */
}
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .instructions-button {
  position: fixed;
  top: 25%;
  left: 29%;
  /* margin-left: 10px; */

}

}
</style>
