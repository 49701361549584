<template>
  <div class="subsc-pg-order">
    <div class="top-pg-hed">
      <h6>Payment Method</h6>
      <h5>{{ $t('message.enter_card_details') }}</h5>
    </div>
    <div class="flx-tow-col">
      <div class="card-details-colm form-css-col">
        <form>
          <label for="input" class="form-label asterisk">Organisation Name</label>
          <input type="text" placeholder="Enter Organisation Name" class="form-control"
            v-model="this.current_user.organisation" />
          <label for="input" class="form-label asterisk">Email</label>
          <input type="email" placeholder="Enter Your Email" class="form-control" v-model="this.current_user.email" />
          <label for="input" class="form-label asterisk">Address</label>
          <input type="address" placeholder="Enter Your Address" class="form-control"
            v-model="this.current_user.address" />
          <label for="input" class="form-label">VAT No.</label>
          <input type="text" placeholder="Enter Your VAT No." class="form-control"
            v-model="this.current_user.vat_number" />
          <label>Card information</label>
          <div id="payment-element"></div>
          <div class="btn-div">
            <button type="submit" v-bind:class="getClass()" id="submit" :disabled="!(
              this.current_user.organisation &&
              this.current_user.email &&
              this.current_user.address
            )
              " @click="handleSubmit" style="marginTop:2vw">
              <div class="spinner hidden" id="spinner"></div>
              <span id="button-text">{{ this.$t('message.pay_now') }}</span>
            </button>
          </div>
        </form>
      </div>
      <div class="gry-sml-rgt-bx">
        <div class="top-pg-hed">
          <h6>Order Summary</h6>
        </div>
        <div class="sub-head">
          <span class="lft-tx-com">Subtotal (€)</span>
          <span class="rgt-tx-com">€ {{ selectedLicense.amount }}</span>
          <!-- <span v-else class="rgt-tx-com">$ {{ selectedLicense.monthly_fee }}</span> -->
        </div>
        <div class="sub-head">
          <span class="lft-tx-com">Mindcoins</span>
          <span class="rgt-tx-com">{{ selectedLicense.mindcoins }}</span>
          <!-- <span v-else class="rgt-tx-com">{{ selectedLicense.credits }}</span> -->

        </div>
        <div class="ext-sml-txt">Subtotal includes all applicable taxes</div>
        <div class="mid-sml-txt">You’re getting the best price we have got</div>
        <div class="seq-pay">
          <img src="../../assets/lock-icon.png" />Secure Payment
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { alert_error } from '@/helpers/alert_helper';
import axios from 'axios';

export default {
  name: 'PaymentForm',
  data() {
    return {
      processingPayment: false,
      elements: this.$props.elements,
      stripe: this.$props.stripe,
      current_user: {
        organisation: '',
        email: '',
        address: '',
        vat_number: '',
      },
    };
  },
  props: {
    elements: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    paymentMethodListEmpty: {
      type: String,
      required: true,
    },
    selectedPaymentMethod: {
      type: String,
      required: true,
    },
    stripe: {
      type: String,
      required: true,
    },
    selectedLicense: {
      type: String,
      required: true,
    },
    newCustomer: {
      type: String,
      required: true,
    },
  },
  created() {
    window.addEventListener('beforeunload', this.reloadHandler);
  },
  mounted: function () {
    document.getElementById('mindspeller').style.overflow = 'auto';
    const paymentElement = this.elements.create('payment');
    paymentElement.mount('#payment-element');
    // this.current_user.organisation = this.$store.state.current_user.organisation.name;
    this.current_user.email = this.$store.state.current_user.email;
    this.current_user.address = this.$store.state.current_user.address;
    this.current_user.vat_number = this.$store.state.current_user.vat_number;
  },
  computed: {
    paymentButtonDisabled: function () {
      if (this.$props.paymentMethodListEmpty) {
        return false;
      } else if (!this.$props.paymentMethodListEmpty) {
        if (this.$props.selectedPaymentMethod === null) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    reloadHandler: function () {
      if (this.$store.state.current_license.monthly_fee > 0) {
        this.$router.push({
          name: 'Upgrade Screen',
          params: {
            isEmpty: true,
          },
        });
      } else {
        this.$router.push({
          name: 'Subscription Plans',
          params: {
            isEmpty: true,
          },
        });
      }
    },
    getClass: function () {
      return {
        btn3:
          this.current_user.organisation &&
          this.current_user.email &&
          this.current_user.address,
        'disabled-btn': !(
          this.current_user.organisation &&
          this.current_user.email &&
          this.current_user.address
        ),
      };
    },
    handleSubmit: async function (e, vue_vm = this) {
      e.preventDefault();

      if (
        this.current_user.organisation &&
        this.current_user.email &&
        this.current_user.address
      ) {
        this.processingPayment = true;
        // If paymentMethodListEmpty is true, then we assume that user checked out
        // using the Stripe iframe.
        var elements = vue_vm.elements;

        const payload = {
          organisation_name: this.current_user.organisation,
          email: this.current_user.email,
          address: this.current_user.address,
          VAT_nr: this.current_user.vat_number,
        };

        try {
          const result = await axios.post(
            '/payment_methods/create_invoice',
            payload
          );

          await vue_vm.stripe
            .confirmPayment({
              elements,
              confirmParams: {
                return_url: window.location.origin,
              },
              redirect: 'if_required',
            })
            .then(async function (response) {
              if (response.error) {
                if (
                  response.error.type === 'card_error' ||
                  response.error.type === 'validation_error'
                ) {
                  vue_vm.processingPayment = false;
                  alert_error(response.error.message);
                } else {
                  vue_vm.processingPayment = false;
                  alert_error(
                    vue_vm.$t('message.payment_failed') + response.error.type
                  );
                }
              } else if (
                response.paymentIntent &&
                response.paymentIntent.status === 'succeeded'
              ) {
                vue_vm.processingPayment = false;
                await vue_vm.$store.dispatch('load_user_data');
                vue_vm.$router.push({
                  name: 'Payment Success',
                  params: {
                    mindcoins: vue_vm.$props.selectedLicense.mindcoins,
                  },
                })
               
              }
            });
        } catch (error) {
          await alert_error(this.$t('message.payment_unexpected_fail'));
        }
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
.card-details-colm.form-css-col input {
  text-transform: capitalize;
}

.card-details-colm.form-css-col input[type='email'] {
  text-transform: inherit;
}

.form-css-col input,
.form-css-col select {
  color: #070707;
}

.card-details-colm.form-css-col {
  padding: 1.5vw;
  background-color: #f0f3f6;
  border-radius: 1vw;
}

.label.p-FieldLabel.Label.Label--empty {
  color: #405261;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.7vw;
  margin-bottom: 0.5vw;
}

.top-pg-hed {
  padding-left: 0 !important;
}

/* .form-css-col label {
  font-weight: 600;
} */
</style>
